import React, { useState } from 'react'
import slider1 from '../Images/Slider/1.webp'
import slider2 from '../Images/Slider/2.webp'
import slider3 from '../Images/Slider/3.webp'
import slider4 from '../Images/Slider/4.webp'
import slider5 from '../Images/Slider/5.webp'

const Banner = () => {


  return (
    <div className='banner'>
      <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel" data-interval="4000">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src={slider1} class="d-block w-100" alt="..." />
            <h5>Unlocking your wealth Potential</h5>
          </div>
          <div class="carousel-item">
            <img src={slider2} class="d-block w-100" alt="..." />
            <h5>Our abilities, your tailored strategy</h5>
          </div>
          <div class="carousel-item">
            <img src={slider3} class="d-block w-100" alt="..." />
            <h5>Curating an Exceptional Journey for Our Valued Clients</h5>
          </div>

          <div class="carousel-item">
            <img src={slider4} class="d-block w-100" alt="..." />
            <h5>Empowering Tax Efficiency</h5>
          </div>

          <div class="carousel-item">
            <img src={slider5} class="d-block w-100" alt="..." />
            <h5>Crafting Your<br /> Legacy</h5>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  )
}

export default Banner
