import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/storage'

firebase.initializeApp({
    apiKey: "AIzaSyC_Q-EIjiVTLLIesBJ8-tewXof0JOjQr6w",
    authDomain: "wise-finserv-cf8b2.firebaseapp.com",
    projectId: "wise-finserv-cf8b2",
    storageBucket: "wise-finserv-cf8b2.appspot.com",
    messagingSenderId: "270934284454",
    appId: "1:270934284454:web:386b6c1f842794f04d3c67",
    measurementId: "G-LVVEPK34DP"
});

const fb = firebase;

export default fb;