import React, { useEffect, useState, useRef } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import contact from '../Images/Contact/contact.webp'
import noida from '../Images/Contact/noida.webp'
import delhi from '../Images/Contact/delhi.webp'
import lucknow from '../Images/Contact/lucknow.webp'
import varanasi from '../Images/Contact/varanasi.webp'
import allahabad from '../Images/Contact/allahabad.webp'
import mumbai from '../Images/Contact/mumbai.webp'
import chandigarh from '../Images/Contact/chandigarh.webp'
import { Helmet } from 'react-helmet'


// import emailjs from '@emailjs/browser';
import { collection, addDoc } from "firebase/firestore";
import { db } from '../AdminPanel/firebase';

const ContactUs = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const form = useRef();
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Phone: "",
    Message: ""
  });
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }
  //connect with firebase

  const SubmitData = async (e) => {
    e.preventDefault();
    const { Name, Email, Phone, Message } = userData;
    if (Name && Email && Phone && Message) {


      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(userData.Email)) {
        alert('Please enter a valid email address');
        return
      }

      if (typeof Phone !== "undefined") {

        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!pattern.test(userData.Phone)) {
          alert("Please enter only number.");
          return

        } else if (userData.Phone.length !== 10) {
          alert("Please enter 10 digit phone number.");
          return
        }
      }

      try {

        const docRef = await addDoc(collection(db, "Contact_Form"), {
          Name: Name,
          Email: Email,
          Phone: Phone,
          Message: Message,
        });

        // emailjs.sendForm('service_wblyans', 'template_n9ua8wv', form.current, 'ke7NgkL-D_6Ssn-xZ')
        //   .then((result) => {
        //     console.log(result.text);

        //   }, (error) => {
        //     console.log(error.text);
        //   });

        alert('Message Sent Successfully');

        setUserData({
          Name: "",
          Email: "",
          Phone: "",
          Message: ""
        })

        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        alert("Error adding document")
      }
    }
    else {
      alert('Please fill the data');
    }
  };



  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.wisefinserv.com/contact-us" />
      </Helmet>
      <div className="contact-page">
        <div class="page-title">
          <h4>Contact Us</h4>
        </div>
        <div className="locations">
          <div className="location-card">
            <div className="location-card-text">
              <img src={noida} alt="" />
              <div className="location-card-caption">
                <h3>Wise Finserv Pvt. Ltd Head Office </h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  G-5, Sector - 3 Noida, Uttar Pradesh - 201301
                </p>
                <a href="tel:01204114832">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0120-4114832
                </a>
                <a href="tel:01204377615">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0120-4377615
                </a>
                <a href="tel:01204312268">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0120-4312268
                </a>
                <a href="mailto:contactus@wisefinserv.com">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
                  contactus@wisefinserv.com
                </a>
                <span>For any grievance addressal please contact our Compliance. The contact details of compliance is mentioned below:</span>
                <b className='compliance'>Compliance: <a href="mailto:compliance@wisefinserv.com"> compliance@wisefinserv.com</a></b>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7007.347632363503!2d77.319067!3d28.579556!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4599cc488a7%3A0xd1035f18d15e1788!2sWise%20Finserv%20Pvt.%20Ltd!5e0!3m2!1sen!2sus!4v1695905720702!5m2!1sen!2sus" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card-2">
            <div className="location-card-text">
              <img src={noida} alt="" />

              <div className="location-card-caption">
                <h3>Noida Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  KLJ Noida One(IT Park) , Plot No B-8, Unit No- C308, <br />
                  Noida Sec-62, Uttar Pradesh-201301
                </p>
                <a href="tel:01203543158">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0120-3543158
                </a>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3502.2473279784344!2d77.36356877550044!3d28.62234857567035!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM3JzIwLjUiTiA3N8KwMjEnNTguMSJF!5e0!3m2!1sen!2sin!4v1696864315140!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card">
            <div className="location-card-text">
              <img src={delhi} alt="" />

              <div className="location-card-caption">
                <h3>Delhi Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  20, Onward Coworkx,
                  First Floor , Okhla Phase 3<br />
                  Okhla Industrial Estate
                  New Delhi-20
                </p>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.5385618421915!2d77.2670069!3d28.5535863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce30c7f783cb1%3A0xcbb8b1da41205f9f!2sOnward%20Workspaces-%20NSIC%20Okhla%20Phase%203!5e0!3m2!1sen!2sin!4v1698750265867!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card-2">
            <div className="location-card-text">
              <img src={varanasi} alt="" />
              <div className="location-card-caption">
                <h3>Varanasi Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  Kuber Complex, Shop no 60,
                  1st Floor, Rathyatra,<br />
                  Varanasi, UP 221010
                </p>
                <a href="tel:05422277990">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0542-2277990
                </a>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3606.893644087544!2d82.9915049264631!3d25.3077771554414!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dffed2a3449%3A0x11a24f4d3fd63f31!2sKuber%20Complex%2C%20Rathyatra%20Sigra%20Rd%2C%20Jahumandi%2C%20Varanasi%2C%20Uttar%20Pradesh%20221010!5e0!3m2!1sen!2sin!4v1696864217079!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card">
            <div className="location-card-text">
              <img src={mumbai} alt="" />
              <div className="location-card-caption">
                <h3>Mumbai Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  Office Unit No 1003,
                  Haware infotech park<br />
                  Plot no 39/3 sector 30A ,
                  Vashi 400703 Mumbai
                </p>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3770.9543774929375!2d72.9993002!3d19.0657434!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c14e84cbb1cf%3A0xec7d66136f702da4!2sHaware%20Infotech%20Park%2C%20Sector%2030A%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!5e0!3m2!1sen!2sin!4v1698750393377!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card-2">
            <div className="location-card-text">
              <img src={lucknow} alt="" />
              <div className="location-card-caption">
                <h3>Lucknow Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  Saran Chamber-1, Park Road,
                  Hazratganj, Lucknow<br />
                  Uttar Pradesh 226001, India
                </p>
                <a href="tel:05224305089">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0522-4305089
                </a>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3559.8795672204556!2d80.94700837427096!3d26.843782563051512!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjbCsDUwJzM3LjYiTiA4MMKwNTYnNTguNSJF!5e0!3m2!1sen!2sin!4v1696864148866!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card">
            <div className="location-card-text">
              <img src={allahabad} alt="" />
              <div className="location-card-caption">
                <h3>Allahabad Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  4C, SN Towers, Maharishi Dayanand Marg,
                  Opp Radio <br />  Station, Civil Lines
                  Allahabad, Uttar Pradesh-211001
                </p>
                <a href="tel:05224305089">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0522-4305089
                </a>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3602.3185546290183!2d81.81880837539043!3d25.461036377542055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDI3JzM5LjciTiA4McKwNDknMTcuMCJF!5e0!3m2!1sen!2sin!4v1696864277303!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>

          <div className="location-card-2">
            <div className="location-card-text">
              <img src={chandigarh} alt="" />
              <div className="location-card-caption">
                <h3>Chandigarh Branch</h3>
                <p>
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                  Cabin No. 53,54,55  SCO 41 Sector 11 Panchkula, 134109
                </p>
                <a href="tel:05224305089">
                  <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                  0522-4305089
                </a>
              </div>
            </div>
            <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3431.1302530887774!2d76.8492778!3d30.6866111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzDCsDQxJzExLjgiTiA3NsKwNTAnNTcuNCJF!5e0!3m2!1sen!2sin!4v1705059814269!5m2!1sen!2sin"></iframe>
          </div>

        </div>
        <div className="contact-page-body">
          <div className="contact-p-left">
            <img src={contact} alt="" />
          </div>
          <div className="contact-p-right">
            <h5>Keep in Touch</h5>
            <form ref={form} id='contact-form' method='POST'>

              <input type="text"
                placeholder='Your Name'
                name='Name'
                value={userData.careers_name}
                onChange={postUserData}
                required />

              <input type="email"
                placeholder='Your Email'
                name='Email'
                value={userData.Email}
                onChange={postUserData}
                required />

              <input type="text"
                placeholder='Your Phone'
                name='Phone'
                value={userData.Phone}
                onChange={postUserData}
                required />

              <textarea
                name='Message'
                value={userData.Message}
                onChange={postUserData}
                required
                placeholder='Your Message'
                rows="6">
              </textarea>
              <button type="submit" onClick={SubmitData}>Send Message</button>
            </form>
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
