import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('blogs');

const LatestPosts = () => {

    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    return (
        <div>
            {blogs.map(blog => (
                <div className='latest'>
                    <Link to={"/show/" + blog.id}>{blog.Title}</Link>
                </div>
            ))}
        </div>
    )
}

export default LatestPosts
