import React, { useState } from 'react'
import clca from "../Images/Investment Philosophy/Client-Centric Approach.webp"
import cowm from "../Images/Investment Philosophy/Comprehensive Wealth Management.webp"
import diarm from "../Images/Investment Philosophy/Diversification And Risk Management.webp"
import com from "../Images/Investment Philosophy/Continuous Monitoring.webp"
import edat from "../Images/Investment Philosophy/Education And Transparency.webp"
import cus from "../Images/Investment Philosophy/Customized Solutions.webp"

const InvestmentPhilosophy = () => {

    const [show, setshow] = useState({
        cca: true,
        cwm: false,
        darm: false,
        cm: false,
        eat: false,
        cs: false,
    })

    const cca = () => {
        setshow({
            cca: true,
        })
    };

    const cwm = () => {
        setshow({
            cwm: true,
        })
    };

    const darm = () => {
        setshow({
            darm: true,
        })
    };

    const cm = () => {
        setshow({
            cm: true,
        })
    };

    const eat = () => {
        setshow({
            eat: true,
        })
    };

    const cs = () => {
        setshow({
            cs: true,
        })
    };

    const investmentPhilosophy = [
        {
            on: cca,
            id: "cca",
            img: clca,
            title: "Client-centric approach",
            description: "Our investment philosophy is deeply rooted in a client-centric approach. We recognize that each client is unique, with their own set of financial goals, risk tolerance, and time horizon. We take the time to understand your individual needs and aspirations, and this understanding forms the foundation of our investment strategies."


        },
        {
            on: cwm,
            id: "cwm",
            img: cowm,
            title: "Comprehensive Wealth Management",
            description: "We go beyond traditional investment advisory services. Our approach involves comprehensive wealth management. This means that we consider all aspects of your financial life, from investment and retirement planning to tax management and estate planning. Our goal is to create a holistic financial plan that encompasses every facet of your financial well-being."

        },
        {
            on: darm,
            id: "darm",
            img: diarm,
            title: "Diversification and Risk Management",
            description: "We believe that diversification is key to managing risk and achieving consistent returns. We carefully construct portfolios that are diversified across asset classes, sectors, and geographies. This not only helps mitigate risk but also captures opportunities for growth."

        },
        {
            on: cm,
            id: "cm",
            img: com,
            title: "Continuous Monitoring",
            description: "Our commitment doesn't end with the initial investment. We believe in continuous monitoring and active management of your portfolio. Market conditions change, and so do your financial goals. We regularly assess your portfolio's performance and make adjustments as needed to keep it in line with your objectives."

        },
        {
            on: eat,
            id: "eat",
            img: edat,
            title: "Education and Transparency",
            description: "We place a strong emphasis on educating our clients. We believe that an informed investor is an empowered investor. We are transparent about our investment strategies and provide you with the information you need to make confident decisions about your financial future."

        },
        {
            on: cs,
            id: "cs",
            img: cus,
            title: "Customized Solutions",
            description: "Your financial journey is unique, and so are our solutions. Whether you are planning for retirement, saving for a child's education, or building wealth for future generations, we tailor our investment strategies to align with your specific objectives."

        },
    ]

    const first = investmentPhilosophy.find((item) => item.id === "cca");
    const second = investmentPhilosophy.find((item) => item.id === "cwm");
    const third = investmentPhilosophy.find((item) => item.id === "darm");
    const fourth = investmentPhilosophy.find((item) => item.id === "cm");
    const fifth = investmentPhilosophy.find((item) => item.id === "eat");
    const sixth = investmentPhilosophy.find((item) => item.id === "cs");

    return (
        <div>
            <div className="investment-philosophy">
                <div className="title">
                    <h4>Our Investment Philosophy</h4>
                </div>
                <div className="page-title">
                    <h4>Our Investment Philosophy</h4>
                </div>
                <p>At Wise Finserv, we hold a steadfast belief that a well-structured and disciplined investment strategy is the bedrock of financial success. We approach investment with a deep commitment to ensuring that your financial goals are not just met but exceeded.</p>
                <div className="investment-content">
                    <div className="investment-philosophy-body">
                        {investmentPhilosophy.map((item) => (
                            <div className="i-p-card" id={item.id} onClick={item.on}>
                                <div className="i-img">
                                    <img src={item.img} alt={item.title} />
                                </div>
                                <h4>{item.title}</h4>
                            </div>
                        ))}
                    </div>
                    <div className="child">
                        {show.cca && (
                            <div className='cca' style={{ animation: "slide-in-right .5s" }} >
                                <h4 >{first.title}</h4>
                                <p>{first.description}</p>
                            </div>

                        )}
                        {show.cwm && (
                            <div style={{ animation: "slide-in-right .5s" }} className='cwm'>
                              <h4 >{second.title}</h4>
                                <p>{second.description}</p>
                            </div>

                        )}
                        {show.darm && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                               <h4 >{third.title}</h4>
                                <p>{third.description}</p>
                            </div>
                        )}
                        {show.cm && (
                            <div style={{ animation: "slide-in-right .5s" }} className='cm'>
                               <h4 >{fourth.title}</h4>
                                <p>{fourth.description}</p>
                            </div>
                        )}
                        {show.eat && (
                            <div style={{ animation: "slide-in-right .5s" }} className='eat' >
                              <h4 >{fifth.title}</h4>
                                <p>{fifth.description}</p>
                            </div>
                        )}
                        {show.cs && (
                            <div style={{ animation: "slide-in-right .5s" }} className='cs'>
                               <h4 >{sixth.title}</h4>
                                <p>{sixth.description}</p>
                            </div>
                        )}
                    </div>
                </div>

                <p>Our investment philosophy is not just a set of principles; it's a commitment to your financial well-being. We are dedicated to helping you navigate the complexities of the financial world and guiding you towards a future of financial security and success.</p>

            </div>
        </div>
    )
}

export default InvestmentPhilosophy
