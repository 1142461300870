import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Helmet } from 'react-helmet'

// Images ========================================
import FixedIncome from "../Images/Investment Products/Fixed Income.webp"
import MutualFunds from "../Images/Investment Products/Mutual Funds.webp"
import PMS from "../Images/Investment Products/PMS.webp"
import AIF from "../Images/Investment Products/AIF.webp"
import StructuredProducts from "../Images/Investment Products/Structured products.webp"
import LifeInsurance from "../Images/Investment Products/Life Insurance.webp"
import HealthInsurance from "../Images/Investment Products/Health Insurance.webp"

// Images ends ===================================

const InvestmentProducts = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const investmentproducts = [
    {
      img: FixedIncome,
      title: "Fixed Income",
      description: "Before making any asset class selection, our approach is rooted in meticulous research and thorough analysis. Fixed income instruments, encompassing government securities, state government bonds, and corporate bonds, assume a pivotal role in maintaining portfolio stability. We carefully curate these assets to ensure predictable, steady income streams, rendering them indispensable for income-focused investors and those desiring capital preservation within a well-diversified portfolio.",
    },
    {
      img: MutualFunds,
      title: "Mutual Funds",
      description: "At Wise Finserv Private Wealth, we place paramount importance on conducting thorough analysis before selecting any asset class. Mutual funds, as part of our investment offering, enable diversification across various asset classes. We engage in in-depth research to meticulously choose the most suitable funds for your portfolio. These mutual funds stand out as ideal options for investors seeking professional management and robust risk mitigation strategies. By doing so, we ensure a well-balanced and diversified investment approach that is intricately tailored to your unique financial goals."
    },
    {
      img: PMS,
      title: "PMS (Portfolio Management Services)",
      description: "Portfolio Management Services (PMS) is expressly designed for high-net-worth individuals and investors seeking personalized, professional investment management. The formulation of our PMS offerings is a result of extensive research and analysis. These services empower investors to craft portfolios tailored to their unique financial goals and risk tolerance. This approach is particularly relevant for individuals seeking a more hands-on investment strategy, as it fosters diversification within their portfolio, aligning precisely with their financial objectives."
    },
    {
      img: AIF,
      title: "AIF (Alternative Investment Funds)",
      description: "We uphold a rigorous approach to analysis before any asset class selection. Alternative Investment Funds (AIFs) represent an avenue that provides access to exclusive investment opportunities extending beyond traditional assets. Our decision to incorporate these options into portfolios is underpinned by meticulous research. Our aim is to diversify portfolios and reduce risk effectively. AIFs are renowned for their potential to enhance returns and offer uncorrelated assets in comparison to traditional stocks and bonds, thus adding an invaluable layer of diversification."
    },

    {
      img: StructuredProducts,
      title: "Structured Products",
      description: "Structured products emerge as a solution tailored to specific investment objectives, offering a unique approach to risk management and the pursuit of desired returns. These products, intricately designed following comprehensive research, ensure a precise alignment with individual risk-return profiles. In essence, they serve as a valuable addition to a diversified investment strategy, aligning perfectly with our unwavering commitment to the principles of thorough analysis and research."
    },
    {
      img: LifeInsurance,
      title: "Life Insurance",
      description: <p>Life insurance recommendations at Wise Finserv revolve around a client-centric approach. We evaluate insurance providers based on claim settlement ratios, service standards, and product features. Our focus is on trustworthy companies with a history of honouring claims.<br /><br />

        Our life insurance advice is driven solely by your needs. We offer transparency and customization, helping you choose a policy that safeguards your loved ones financially. Your unique circumstances and financial goals are central to our recommendations, ensuring your family's security in unforeseen situations.</p>
    },
    {
      img: HealthInsurance,
      title: "Health Insurance",
      description: <p>At Wise Finserv, our health insurance recommendations prioritize your unique needs. We assess plan coverage comprehensively, evaluating inpatient and outpatient services, prescription coverage, and preventive care. Service quality is a top concern, including claims processing and customer support responsiveness.<br /><br />
        We understand health insurance is a personal decision. Our recommendations consider the features of policies, such as optional riders and wellness programs, aligning them with your health and financial goals. We ensure that you have the right coverage for your well-being and peace of mind, based on your specific requirements.</p>
    },
  ]

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Investment Products | Wise FinServ Private Wealth | Unlocking Your Wealth Potential</title>
        <link rel="canonical" href="https://www.wisefinserv.com/investment-products" />
        <meta name="description" content="Explore a diverse range of investment products offered by Wise FinServ Private Wealth. Discover Fixed Income, Mutual Funds, Portfolio Management Services (PMS), Alternative Investment Funds (AIF), Insurance, Structured Products, Health Insurance, and much more, designed to unlock your wealth potential." />
        <meta name="keywords" content="Wise FinServ, investment products, Fixed Income, Mutual Funds, PMS, AIF, insurance, Structured Products, Health Insurance, Portfolio Management Services, financial solutions, wealth potential" />
      </Helmet>
      <Header />
      <div className="investment-products">
        <div class="page-title">
          <h4>Investment products</h4>
        </div>
        <div className="investment-products-body">
          <div className="head">
            <p>We employ a wide range of financial instruments, aligning them with our house views and considering your suitability, to craft customized solutions tailored specifically to your needs</p>
          </div>
          <div className="investment-content">
            {investmentproducts.map((item) => (
              <div className="i-c-card">
                <div className="i-c-card-img">
                  <img src={item.img} alt={item.title} />
                </div>
                <div className="i-c-card-text">
                  <h5>{item.title}</h5>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default InvestmentProducts
