import React, { useState } from 'react'

const WhyChooseUs = () => {

    const [show, setshow] = useState({
        eae: true,
        tfs: false,
        cs: false,
        cca: false,
        at: false,
        gr: false,
        rm: false,
        cte: false,
        eari: false
    })


    const eae = () => {
        setshow({
            eae: true,
        })
    };

    const tfs = () => {
        setshow({
            tfs: true,
        })
    };

    const cs = () => {
        setshow({
            cs: true,
        })
    };

    const cca = () => {
        setshow({
            cca: true,
        })
    };

    const at = () => {
        setshow({
            at: true,
        })
    };

    const gr = () => {
        setshow({
            gr: true,
        })
    };

    const rm = () => {
        setshow({
            rm: true,
        })
    };

    const cte = () => {
        setshow({
            cte: true,
        })
    };

    const eari = () => {
        setshow({
            eari: true
        })
    };

    const whyChooseUs = [
        {
            on: eae,
            id: "eae",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674225/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Expertise_and_Experience_rv2axk.png",
            title: "Expertise and Experience",
            description: "Wise Finserv brings a wealth of experience to the table. Our team comprises seasoned professionals with extensive knowledge of financial markets, investment strategies, tax planning, and estate management. With a track record of delivering results, we have the expertise to navigate complex financial landscapes."
        },
        {
            on: tfs,
            id: "tfs",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028450/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Tailored_Financial_Solutions_nioy8a.png",
            title: "Tailored Financial Solutions",
            description: "We understand that every client has unique financial goals, risk tolerance, and circumstances. At Wise Finserv, we don't offer one-size-fits-all solutions. Instead, we take the time to understand your individual needs and design custom-tailored financial plans and investment portfolios to help you achieve your specific objectives."
        },
        {
            on: cs,
            id: "cs",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028450/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Comprehensive_Services_dptint.png",
            title: "Comprehensive Services",
            description: "Wise Finserv offers a full spectrum of financial services. Whether you're looking for investment portfolio management, retirement planning, estate planning, tax optimization, or any other financial need, our team has the expertise to provide holistic solutions that align with your financial goals."
        },
        {
            on: cca,
            id: "cca",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674221/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Client-Centric_Approach_rukjnt.png",
            title: "Client-Centric Approach",
            description: "We prioritize our clients' interests above all else. Our client-centric approach means that your financial well-being and peace of mind are at the forefront of every decision we make. We are committed to transparency, open communication, and building long-lasting relationships based on trust."
        },

        {
            on: at,
            id: "at",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674220/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Advanced_Technology_zwjxop.png",
            title: "Advanced Technology",
            description: "We leverage cutting-edge technology to provide you with real-time access to your financial information and performance metrics. Our secure online portals and digital tools ensure that you have complete visibility and control over your wealth. <i>Our website is 256 bit encrypted & secured."
        },
        {
            on: rm,
            id: "rm",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674227/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Risk_Management_p61iqj.png",
            title: "Risk Management",
            description: "Wise Finserv is dedicated to managing risk effectively. We conduct thorough risk assessments and develop investment strategies that align with your risk tolerance, ensuring a balanced and suitable approach to wealth accumulation."
        },
        {
            on: gr,
            id: "gr",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674226/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Global_Reach_twvgnv.png",
            title: "Global Reach",
            description: "In today's interconnected world, global diversification is essential for wealth preservation and growth. Wise Finserv offers expertise in international markets, helping you diversify your investments to capitalize on opportunities worldwide."
        },
        {
            on: cte,
            id: "cte",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674222/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Commitment_to_Education_assixs.png",
            title: "Commitment to Education",
            description: "We believe in empowering our clients with knowledge. Wise Finserv provides regular updates on financial markets, economic trends, and investment strategies, ensuring that you are well-informed and can make confident financial decisions."
        },
        {
            on: eari,
            id: "eari",
            img: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674224/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Ethical_and_Responsible_Investing_maqvmd.png",
            title: "Ethical and Responsible Investing",
            description: "We take environmental, social, and governance (ESG) factors into account when constructing investment portfolios. If you have a preference for responsible and sustainable investing, Wise Finserv can align your investments with your values."
        }

    ]

    const first = whyChooseUs.find((item) => item.id === "eae");
    const second = whyChooseUs.find((item) => item.id === "tfs");
    const third = whyChooseUs.find((item) => item.id === "cs");
    const fourth = whyChooseUs.find((item) => item.id === "cca");
    const fifth = whyChooseUs.find((item) => item.id === "at");
    const sixth = whyChooseUs.find((item) => item.id === "rm");
    const seventh = whyChooseUs.find((item) => item.id === "gr");
    const eighth = whyChooseUs.find((item) => item.id === "cte");
    const ninth = whyChooseUs.find((item) => item.id === "eari");

    return (
        <div>
            <div className="investment-philosophy why">
                <div className="title">
                    <h4>Why Choose Us</h4>
                </div>
                <div className="investment-content">
                    <div className="investment-philosophy-body">
                        {whyChooseUs.map((item) => (
                            <div className="i-p-card" id={item.id} onClick={item.on}>
                                <div className="i-img">
                                    <img src={item.img} alt={item.title} />
                                </div>
                                <h4>{item.title}</h4>
                            </div>

                        ))}
                    </div>

                    <div className="child">
                        {show.eae && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{first.title}</h4>
                                <p>{first.description}</p>
                            </div>
                        )}

                        {show.tfs && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{second.title}</h4>
                                <p>{second.description}</p>
                            </div>
                        )}

                        {show.cs && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{third.title}</h4>
                                <p>{third.description}</p>

                            </div>
                        )}

                        {show.cca && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{fourth.title}</h4>
                                <p>{fourth.description}</p>
                            </div>
                        )}

                        {show.at && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{fifth.title}</h4>
                                <p>{fifth.description}</p>
                            </div>
                        )}

                        {show.rm && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{sixth.title}</h4>
                                <p>{sixth.description}</p>
                            </div>
                        )}

                        {show.gr && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{seventh.title}</h4>
                                <p>{seventh.description}</p>
                            </div>
                        )}

                        {show.cte && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{eighth.title}</h4>
                                <p>{eighth.description}</p>
                            </div>
                        )}

                        {show.eari && (
                            <div style={{ animation: "slide-in-right .5s" }} >
                                <h4>{ninth.title}</h4>
                                <p>{ninth.description}</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default WhyChooseUs
