import React from 'react'
import { Carousel } from "react-responsive-carousel";

import RavindraKumarSingh from "../Images/Testimonials/Ravindra_Kumar_uyk41j.webp";
import PearlyLalDhar from "../Images/Testimonials/Pearey_Lal_Dhar_e7gdqe.webp";
import KavitaKhatri from "../Images/Testimonials/Kavita_Khatri_pgedqr.webp";
import LalitMohanTalwar from "../Images/Testimonials/Lalit_Mohan_Talwar_as23vq.webp";
import ChandDevPuri from "../Images/Testimonials/Chand_Dev_Puri_qpmgfu.webp";
import ManjariSingh from "../Images/Testimonials/Manjari_gavbvw.webp";
import women from "../Images/Testimonials/women_dx3crs.webp";
import men from "../Images/Testimonials/men_v75ybd.webp";
import SurendraVerma from "../Images/Testimonials/Surendra_Verma_f2sspd.webp";
import LeenaBhattacharya from "../Images/Testimonials/Leena_Bhattacharya_itrgb1.webp";
import ArundhatiSinha from "../Images/Testimonials/Arundhati_Sinha_g1uaz6.webp";
import SRPahwad from "../Images/Testimonials/S_R_Pahwa_gi8f9z.webp";
import SarojiniDatta from "../Images/Testimonials/sarojini_datta_gnnoyn.webp";
import UmeshGupta from "../Images/Testimonials/umesh_gupta_dvfzbl.webp";
import AjitSingh from "../Images/Testimonials/Ajit_Singh_rosvtv.webp";
import AnandVerma from "../Images/Testimonials/Anand_kr.verma_i55krg.webp";
import VirenderSharma from "../Images/Testimonials/virender_sharma_ii1n0h.webp";
import KritikaSingh from "../Images/Testimonials/kritika_singh_wzfz2k.webp";
import RohitBhandary from "../Images/Testimonials/rohit_bhandary_qdrbxl.webp";
import VinitGupta from "../Images/Testimonials/vinit_gupta_rv0rge.webp";
import TauheedAhmed from "../Images/Testimonials/tauheed_ahmed_hv9obs.webp";
import SanjeevKumar from "../Images/Testimonials/sanjeev_kumar_vy1xql.webp";
import AmitYadav from "../Images/Testimonials/Amit_Yadav_1_jufchl.webp";
import VinayMohanJoshi from "../Images/Testimonials/vinay_mohan_joshi_a9hhzv.webp";
import UmeshTaneja from "../Images/Testimonials/umesh_taneja_xyk0av.webp";
import ShrutiChaubey from "../Images/Testimonials/shruti_chaubey_v3q5du.webp";
import LeenaLaloo from "../Images/Testimonials/leena_laloo_ueqdvr.webp";
import AnkurSrivastava from "../Images/Testimonials/ankur_srivastva_i6hiwq.webp";
import AyusPratapSingh from "../Images/Testimonials/ayush_pratap_singh_iojhmm.webp";
import SanjeevSolanki from "../Images/Testimonials/sanjeev_solanki_sicli0.webp";
import VivekKumarSingh from "../Images/Testimonials/vivek_kumar_singh_rosjtj.webp";
import RajeshSingh from "../Images/Testimonials/rajesh_singh_qp8s5q.webp";
import DhaneshKaushik from "../Images/Testimonials/dhanesh_kaushik_jvcmlr.webp";
import RaghvendraSingh from "../Images/Testimonials/raghvendra_singh_lpbnip.webp";
import UmeshKumarSingh from "../Images/Testimonials/umesh_kumar_singh_xg8hko.webp";
import PiyushSingh from "../Images/Testimonials/piyush_singh_c6fgxu.webp";
import LalitKhajuria from "../Images/Testimonials/lalit_khajuria_qnw4dh.webp";
import DilipChaturvedi from "../Images/Testimonials/dilip_chaturvedi_xltlit.webp"

const Testimonial = () => {

    const data = [
        [
            {
                imgUrl: RavindraKumarSingh,
                content: <p>An excellent financial advisory company with well qualified and capable staff. They fully justify their logo of "Edifying Wealth". They are very prompt in their response, help and give well researched advice keeping client requirements.</p>,
                Name: "Ravindra Kumar singh",
                Designation: "CEO, MINDEDIFIERS"
            },
            {
                imgUrl: PearlyLalDhar,
                content: <p>I have immense pleasure in certifying the credibility & sincerity to Wise Finserv. They are a young team of professionals who has put in a number of years in banking industry in Wealth Management. I have had the good fortune of being in contact with her since then. Their service & advise is reliable and they keep to their schedules. I wish this young team of Wise Finserv a great future & greater heights.</p>,
                Name: "Pearey Lal Dhar",
                Designation: "Retd. SVP, ISGEC"

            },
        ],
        [
            {
                imgUrl: men,
                content: <p> I am very much pleased with my more than 10 year’s long relationship with 'Wise FinServ'. They are dependable partners, who help me realize my financial goals. The talented experts at 'Wise FinServ' have created customized portfolio as per my requirements and proactively track and periodically share the progress. The partner’s at 'Wise FinServ' also suggests and help execute the readjustments as per the prevailing market conditions to ensure good returns.<br /><br />

                    In summary I can be assured of excellent returns on my investments with 'Wise FinServ', who meet all needs under single roof. I wish them well and success in coming years.</p>,
                Name: "Harish Chauhan",
                Designation: "Sr. Staff R&D Engineer, Synopsys In"
            },
        ],
        [
            {
                imgUrl: KavitaKhatri,
                content: <p>I was very wary of using financial advisers but this young team has helped me get over that mistrust. I trust them to provide me the right guidance and take care of my money. They know my priorities and invest accordingly and I feel my retirement is in safe hands. Wise Finserv team are always available when I need them and I wish wise Finserv for continued success in their venture.</p>,
                Name: "Kavita Khatri",
                Designation: "Whirlpool Corporation"
            },
            {
                imgUrl: LalitMohanTalwar,
                content: <p>I have been dealing with them for about 10 years when she was with ICICI. I continue to have her when our investment manager even after she left ICICI and started her own. I have found her very hardworking and well informed about investment plans. I have no hesitation to recommend her for investment deals.</p>,
                Name: "Lalit Mohan Talwar",
                Designation: "Retd. Executive Director, ISGEC"
            },

        ],
        [
            {
                imgUrl: men,
                content: <p>I have been using the services of Wise Financial Services for over 2 years and have invested substantial amount with them. Apart from earning great returns on investment I have found their services to be very prompt, efficient and reliable. I live in New Zealand and can view my investment through their online portal which is a blessing. Wise Finserv Team is always a phone away if I need advise or have a query. I can recommend their services to any prudent investor as they are honest, reliable and you can conduct business over the phone or by sending a simple email. </p>,
                Name: "Praveen Kumar",
                Designation: ""
            },

            {
                imgUrl: ChandDevPuri,
                content: <p>The team is managing my portfolio since last 7 years and I am happy to have them as my wealth managers. I have a customized portfolio as per my need. They always ensure alignment of my portfolio as per dynamics of market.</p>,
                Name: "Chand Dev Puri",
                Designation: "Retd. MD, Scot Wilson"
            },
        ],
        [
            {
                imgUrl: men,
                content: <p> I was always very apprehensive of the investment “gyanis” who used to try & convince me to invest with them, thanks to the one horrible experience I had with a very large bank.<br /><br />

                    I must compliment Wise Finserv team to have pursued & followed up with me for over a year without getting any business. After I started investing thru, my experience has been quite phenomenal. My funds are doing well & I have to hardly worry about the market conditions as you & your team proactively monitor these factors & just contact me for permissions to make changes.<br /><br />

                    Thanks Again & best of luck for the entire team</p>,
                Name: "Rahul Kulshreshtha",
                Designation: "Principal Architect, Benchmark Broadcast Systems"
            },

        ],
        [
            {
                imgUrl: ManjariSingh,
                content: <p>I am a client of Wise Finserv for almost two and a half years. I am very grateful for the prompt and sound financial services. They have helped me to build a customized portfolio according to my specific needs. I wish them all the best for future.</p>,
                Name: "Manjari singh",
                Designation: "Lawyer, Allahabad High Court"
            },

            {
                imgUrl: SurendraVerma,
                content: <p>We were introduced to M/s Wise Finserv about 2 years ago. Since then, they have been handling my Financial & Investment portfolio. Their advice has been very valuable, and under their guidance, the invested funds have yielded high profits. They are highly professional and above all well spoken and considerate. We have no hesitation in strongly recommending their services and expert advice.</p>,
                Name: "Surendra Verma",
                Designation: "Retd-COL, From Indian Army"
            },
        ],
        [
            {
                imgUrl: DilipChaturvedi,
                content: <p>Wise Finserv are the cleanest and the finest service provider. I can trust you to the core because of your focused and appropriate suggestion for the development and progressive growth. I have not entertained any request since you look after mine and my family member’s portfolios. Wise Finserv team are Punctual and correct with your action. I certainly wish that your financial and service advises will reach to lot of people and you and your wonderful team flourish. Service with smile is your forte. I wish you and your team to do well continuously and consistently. </p>,
                Name: "Dilip Chaturvedi",
                Designation: "General Manager, Reliance Industries"
            },
        ],
        [
            {
                imgUrl: LeenaBhattacharya,
                content: <p>I am highly satisfied with the services of Wise Finserv. They are very helpful, trustworthy and efficient. And it would be worthwhile to mention that their personal touch is commendable.</p>,
                Name: "Leena Bhattacharya",
                Designation: "Retd. Professor"
            },

            {
                imgUrl: ArundhatiSinha,
                content: <p>My experience with Wise FinServ has been very good. Financial Planning now seems simpler and all their recommendations have helped me grow my wealth.</p>,
                Name: "Arundhati Sinha",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I have had the pleasure of using Wise Financial Services for quite some time and have been very happy for this experience, specially in India. Quite frequently in India I have felt that once they get your business, they want to forget you or deal as little as possible or follow-up.<br /><br />

                    The best experience is to feel that your views, objectives and interests are of prime consideration for the services being offered. I have always felt that Wise Financial always kept this in view, including making it convenient for me, despite I am sure many other pressure of being in the business of financial services. Even when my views or options where not similar with the expert advice of Wise Financial Services, the situation was handled very well and with patience.<br /><br />

                    I feel very happy to see you and your colleagues who have the dedication, to succeed in business and gain the confidence of investors like me in the financial instruments available here in India. </p>,
                Name: "Mohan Prasad Singh",
                Designation: "Retd. Financial Services Industry (U.S.A)"
            },
        ],
        [
            {
                imgUrl: SRPahwad,
                content: <p>I am extremely happy with the wealth management services of Wise Finserv. They are managing my portfolio since last 6 years and I always get very quality advice from them. Above all the services are very prompt.</p>,
                Name: "S R Pahwad",
                Designation: "Retd. Director, AMCO"
            },

            {
                imgUrl: SarojiniDatta,
                content: <p>It has been my good luck to have encountered very sincere and knowledgeable financial consultants who have helped me to invest wisely and safely after my husband's demise 8 years ago. Their courtesy, honesty and understanding of my financial requirements along with their readiness to address my issues have made me trust them completely. I have continued to let them take charge of all my investments with the confidence that they will always park my limited assets in the best way possible.</p>,
                Name: "Sarojini Datta",
                Designation: "Boutique Owner"
            },
        ],
        [
            {
                imgUrl: women,
                content: <p>Dear team,<br /><br />

                    I have been using your services since last 6-7 months. Your team enhanced my knowledge and trust on the subject of savings.
                    I always get good response from your end with solution of my queries.<br /><br />

                    My funds are working fine, hope to be associated with you always.<br /><br />

                    Thanks,<br />
                    Ritu Soni</p>,
                Name: "Ritu Soni",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: UmeshGupta,
                content: <p>It has been sometime since me and my wife are consulting WISE FINSERV for financial investments. Wise Finserv team are a concerned lot and take good care of our wealth. They are trustworthy and prompt with queries. I am certain that their diligence both in their knowledge and technical resources will grow our investments exponentially. </p>,
                Name: "Umesh Gupta",
                Designation: "Co-Founder, Momentous Pictures Private Limited"
            },

            {
                imgUrl: AjitSingh,
                content: <p> Wise Finserv Team has been my financial advisor for last four years. I have invested more than 60% of my retirement benefits following his advice. I have never regretted it. My money has been growing.I think Wise Finserv is an organization which is completely reliable. I have full confidence in Wise Finserv team.</p>,
                Name: "Ajit Singh",
                Designation: "Retd. Addl. Secy., Cab Sec"
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Dear team Wise Finserv,<br /><br />

                    “I am absolutely delighted with your service. It is really refreshing to deal with a financial adviser who is truly interested in their client’s needs, circumstances and preferences. What really impressed me was the way you took the time to get a feeling for where i was at, your depth of knowledge, lateral thinking and your common sense approach. Your professional, ethical and caring demeanour elicits my trust and respect and i gladly recommend your services whenever possible.”<br />

                    Saurav Verma – Client since 2016 </p>,
                Name: "Saurav Verma",
                Designation: ""
            },

        ],
        [

            {
                imgUrl: men,
                content: <p> I am very happy with the services of Wise Finserv. My advisor is very faithful and trustworthy. And advices me according to my needs and want.
                    I recommend others too to get connected to Wise Finserv.</p>,
                Name: "Mr. Vishal Sharma",
                Designation: ""
            },

            {
                imgUrl: men,
                content: <p> The team is very competitive and is always reachable. They always ensure asset allocation as per my risk profile. I wish them all the very best.

                </p>,
                Name: "Ram Kumar sharma",
                Designation: "Adviser, PricewaterhouseCoopers (P) Ltd"
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Hello Team,<br /><br />

                    I am a client of Wise Finserv for almost one and a half years. I am very grateful for the prompt and sound financial services. They have helped me to build a customized portfolio according to my specific needs.<br /><br />

                    I wish this young team of Wise Finserv a great future & greater height.<br /><br />

                    Thanks<br />
                    Ajay Agrahari </p>,
                Name: "Ajay Agrahari",
                Designation: ""
            },

        ],
        [
            {
                imgUrl: AnandVerma,
                content: <p>Wise Finserv offers very competitive Wealth Management services and the key people at Wise are managing my portfolio since last 7 years. They are quick in terms of their services and always reachable. </p>,
                Name: "Anand Kr. Verma",
                Designation: "Retd. Govt. Services"
            },


            {
                imgUrl: men,
                content: <p>It's being an excellent experience with Wise Finserv and their financial advisor. They have done a fantastic work by providing me updates about where to invest which policy to take and daily updates about the economics news. </p>,
                Name: "Sahil chaudhary",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p> Dear Wise Finserv,<br />
                    I am associated to your organization  from last 7-8 months and i m very thankful to you the way your people have awarded me about the different investments aspects. I want to thanks my relationship manager for her valuable advice and time to time update on my portfolio. I also appreciate her prompt action/update whenever i required. I will look for a long and healthy relationship with your organization.<br /><br />

                    All the best!!!<br />
                    Regards,<br />
                    Abhimanyu singh</p>,
                Name: "Abhimanyu Singh",
                Designation: ""
            },

        ],
        [

            {
                imgUrl: men,
                content: <p>Hello,<br /><br />

                    I am happy and satisfied with your services. Over the period of time my relationship manager has provided valuable suggestions regarding investments. Looking forward for more valuable suggestions in future. Keep up the good work. Thanks & regards,<br /><br />

                    Shiva rastogi </p>,
                Name: "Shiva Rastogi",
                Designation: ""
            },

            {
                imgUrl: men,
                content: <p>Dear all,<br />
                    It has been a great experience investing with you. My advisor is looking after my portfolio and she is really sincere at her work. I am always been assisted accordingly whenever it is required.<br /><br />

                    Regards <br />
                    Sarvesh Malhotra </p>,
                Name: "Sarvesh Malhotra",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I am absolutely delighted with your service. It is really refreshing to work with a financial adviser who is truly interested in their client’s needs, circumstances and preferences. What really impressed me was the way you took the time to get a feeling for where i was at, your depth of knowledge, lateral thinking and your common sense approach. Your professional, ethical and caring demeanour elicits my trust and respect. It would be further nice in person/via skype discussions can happen, which i think, would add compassion. In total, i gladly recommend your services whenever possible.<br /><br />

                    Regards<br />
                    Danish </p>,
                Name: "Danish khan",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Sir, I am very happy infact much glad to be stakeholder of Wise Finserv incredible service given by company to me in the concept of investment. Wise Finserv is a good company with better framework company provide time to time market updates which very helpful.<br /><br />

                    Better service.<br />
                    Better managing staff<br />
                    Intelligence team.</p>,
                Name: "Deepak Sharma",
                Designation: ""
            },
            {
                imgUrl: men,
                content: <p>I am satisfied with the services of your company and would like to have a long term association.<br /><br />

                    RM Bharat is good, i get regular updates and prompt and good guidance.<br /><br />

                    Thanks<br />
                    Regards
                    Gaggan Taneja.</p>,
                Name: "Gaggan Taneja",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: VirenderSharma,
                content: <p> Dear mam,<br /><br />

                    I would like to state that my experience with Wise Finserv has been extremely well.  Prior to associate with Wise Finserv i was actually unknown to the world of investment.  I knew only about the conventional type of investments which our parents had taught to us.  Post my association with Wise Finserv i discovered the scientific way of investing my savings and that is too in liquid state.  I also discovered a new horizon of living life with enthusiasm and confidence within the same amount of resources.  A proven and tested method to achieve my goals of life within my income without compromising on my standard of living.  I would recommend everyone to join Wise Finserv and explore the world of right investments.</p>,
                Name: "Virender Sharma",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: KritikaSingh,
                content: <p>Dear ma'am,<br />
                    I have appreciated the time Wise Finserv gave to me, whilst discussing and reviewing my needs for creating my financial plan. I was heard, regarded and respected and assisted all the way. All economic update is shared with me by my financial advisor that are really helpful to know the current market conditions. I am very thankful that i am investing my funds with such a professional team.<br /><br />

                    Regards,<br />
                    Kritika singh</p>,
                Name: "Kritika Singh",
                Designation: "Operation Executive, Optima Insurance Brokers Pvt. Ltd."
            },
            {
                imgUrl: men,
                content: <p>It was really wonderful meeting with you. Your way of approach was supreme , i appreciate your efforts that how sincere are you for the company.</p>,
                Name: "Faiz khan",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Dear Sir<br /><br />

                    It's a pleasure to be associated with Wise Finserv.<br />
                    It was a great experience for me which help me for financial planning.<br /><br />

                    Representatives have great convincing ability, good guidance, motivation and all.<br /><br />

                    I hope good growth for me as well as you all.<br /><br />

                    Thanks </p>,
                Name: "Nitin Tyagi",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Its been great working with you. You have taken good care of all of my investments. You have been a great help.<br /><br />

                    Thanks & regards.</p>,
                Name: "Hitesh Samant",
                Designation: ""
            },
            {
                imgUrl: men,
                content: <p>Dear Sir/ Madam,<br /><br />

                    I think approx. from last one year i have touched with mutual fund from Wise Finserv i am very happy to given her suggestion, which related to me & my goal.<br /><br />

                    I think if we will do this work in same platform, then i will be reach my goal quickly.<br /><br />

                    Thanks & regards<br />
                    Vijay Ahuja</p>,
                Name: "Vijay Ahuja",
                Designation: ""
            },

        ],
        [
            {
                imgUrl: men,
                content: <p>Dear Mam/Sir<br /><br />

                    I would like to say that Wise Finserv have supportive staff, they have also very good convincing and problem handing ability. Team Wise Finserv always help me to understand about mutual fund, review of my finance budge etc.
                    So its feel good to associate with you .<br /><br />

                    Thanks with regard<br />
                    Ram Jatan Yadav<br />
                    Jims greater Noida</p>,
                Name: "Ram Jatan Yadav",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I had never consulted any financial advisor for my financial planning’s till 2016, but after my few meetings with Wise Finserv team i realised that how important financial planning is for every individual especially for working professionals.<br /><br />

                    Best regards,<br />
                    Sarvesh Dubey</p>,
                Name: "Sarvesh Dubey",
                Designation: ""
            },
            {
                imgUrl: men,
                content: <p>Hi<br /><br />

                    I have had a wonderful relationship with the organization and their representatives. They have taken a very good care of my finances and helped me immensely in putting things in order as far as my finances are concerned. Keep up the good work and make my finance grows many more folds.<br /><br />

                    Thanks<br />
                    Best Regards
                    Abhishek Gautam</p>,
                Name: "Abhishek Gautam",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: RohitBhandary,
                content: <p>Dear Wise Finserv,<br /><br />

                    Thank you for taking care of my finances.<br /><br />

                    I am absolutely delighted with your service. It is really refreshing to work with a financial adviser who are truly interested in their client’s needs, circumstances and preferences. What really impressed me was the way you took the time to get a feeling for where i was at, your depth of knowledge, lateral thinking and your common sense approach. Your professional, ethical and caring demeanour elicits my trust and respect and i gladly recommend your services whenever possible.<br /><br />

                    Thank you for being there for me.<br /><br />

                    Regards,
                    Rohit Bhandary</p>,
                Name: "Rohit Bhandary",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: VinitGupta,
                content: <p>Greetings for the day!<br /><br />

                    It's my pleasure to have a collaboration with a sebi registered firm. Though it is a very short span since my association with WFS but your advices help me alot. I wish all the success to Wise Finserv</p>,
                Name: "Vinit Gupta",
                Designation: ""
            }, {
                imgUrl: men,
                content: <p>Dear Team,<br /><br />

                    The experience with Wise Finserv has been extremely wonderful for handling my portfolio very well. One experience i want to share with Wise Finserv is that i invested in some bond 10 years back through cash transaction & i don't have any document of that bond i coordinate with financial advisor she help me out from that problem along with whole amount in my new bank this hard work is totally commendable. I will recommend everyone to associate with Wise Finserv.<br /><br />

                    Hats off to Wise Finserv team...<br /><br />

                    Regards</p>,
                Name: "Rupinder Kaur",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hello Everyone,<br /><br />

                    It is nice to be in touch with Wise Finserv for almost last one year. I am happy to have your services. You are my financial doctor and very supportive. You are always there to help me with my financial planning. After getting in touch with your team i am much concern about my money & future financial goal.<br /><br />

                    Thanks Wise Finserv for your all assistance.<br /><br />

                    Kind regards,<br />
                    Anil Kumar</p>,
                Name: "Anil Kumar",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I trust in Wise Finserv. The information i receive from my advisor give `me a confidence level in her knowledge. I feel a much more personal connection with Wise Finserv than with other financial companies.</p>,
                Name: "Rohit Chawla",
                Designation: ""
            },

            {
                imgUrl: TauheedAhmed,
                content: <p>Hello's<br />
                    I am very happy for the service which Wise Finserv had provided me.<br />
                    I am looking for more investment through Wise Finserv.<br /><br />

                    Thanks & regards,<br />
                    Tauheed Ahmed</p>,
                Name: "Tauheed Ahmed",
                Designation: "Operation Executive, Optima Insurance Brokers Pvt. Ltd."
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Hi,<br /><br />

                    I would like to express my thanks for the work you have done for me over the past year. The expertise and attention to detail with which you have handled my financial affairs could not be faulted. Your continual advice on financial planning issues has saved my significant amounts of money. I want to say thanks my advisor for giving me advice regarding funds and insurances. She always explains everything thoroughly and i never feel uncomfortable asking questions or expressing an opinion. May you prosper, along with your clients.<br /><br />

                    Regards,<br />
                    Nishant Varshney</p>,
                Name: "Nishant Varshney",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>It is great working with you guys.<br /><br />

                    You have taken care of my financial issues very well and i am satisfied with your services.<br /><br />

                    You guys are very helpful and assists me very well regarding my financial decisions. He has a good knowledge of the current financial matters and keep me update too for the same via mail and personal messages. He is always available to assist me whenever i need him. I am definitely working with him for my future financial decisions.</p>,
                Name: "Abhishek Juyal",
                Designation: ""
            },
            {
                imgUrl: men,
                content: <p>As a result of using your service i found them knowledgeable, fast, friendly. It was a great decision to choose you! The specifically i liked most about you is your advice for investment! Two other benefits important to me are your speed in completing the job (you are very fast!) And friendly service.<br /><br />

                    I am very happy with Ekta Mehta guidence.</p>,
                Name: "Abhishek Srivastava",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Dear team<br /><br />

                    I would take this opportunity to really thank your team, for really pursuing me to do some investments for future. Her grit and caring nature is admirable, which feels like one is talking to a friend, than just a financial advisor.<br /><br />

                    Really appreciate your skills and research and congrats on your new app.<br /><br />

                    Keep up the good work..!<br /><br />

                    Thank you..!<br />
                    Arun</p>,
                Name: "Arun Kakkar",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: SanjeevKumar,
                content: <p>Hi,<br /><br />

                    "It was a overwhelming experience of my association with Wise Finserv. They are really supporting me in my retirement planning and helping me in achievement of my other financial goals. I really like their work."<br /><br />

                    Regards,<br />
                    Sanjeev Kumar</p>,
                Name: "Sanjeev Kumar",
                Designation: ""
            },
            {
                imgUrl: men,
                content: <p>Hi,<br /><br />

                    you has been doing an outstanding job managing not only mine, but also my family's portfolio. Whatever you has suggested have generally come thru very well and we trust you like our own. You guys always there whenever we call , thank you for your hard work and dedication.<br />

                    Warm regards,<br />
                    Akash</p>,
                Name: "Akash",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: women,
                content: <p>Chetna
                    I am happy to provide my testimonial for WiseFinServ.<br /><br />

                    It's been more than a year since I have been engaged with WiseFinServ. I primarily interact with Tripti Sharma and Swati Malik, with Tripti being the lead Financial Consultant/Advisor for me. I hugely appreciate the readiness and responsible attitude shown by both. They have patiently answered my questions and addressed my concerns as swiftly as they could have. Having trust and a personal connect with one's customer/client is very crucial and Tripti/Swati meet that mark !<br /><br />

                    My only suggestion would be to have some senior Consultants/Management have informal meetings with important clients (individuals or corporates). It creates more touchpoints within an organization not to speak of more core information sharing.<br /><br />

                    Thanks,<br />
                    Vandana<br />
                    Chetna</p>,
                Name: "Chetna",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: AmitYadav,
                content: <p>Dear mam,<br /><br />

                    I am happy with the services of Wise Finserv. I am happy to know that one financial advisor is there who is taking care of my portfolio so dedicatedly.</p>,
                Name: "Amit Yadav",
                Designation: ""
            }, {
                imgUrl: men,
                content: <p>Excellent services by Wise Finserv for handling my mf</p>,
                Name: "Sandy Singh",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: women,
                content: <p>I am happy to provide my testimonial for Wise Finserv.<br /><br />

                    It's been more than a year since i have been engaged with Wise Finserv. I hugely appreciate the readiness and responsible attitude shown by both. They have patiently answered my questions and addressed my concerns as swiftly as they could have. Having trust and a personal connect with one's customer/client is very crucial and tripti/swati meet that mark !<br /><br />

                    My only suggestion would be to have some senior consultants/management have informal meetings with important clients (individuals or corporates). It creates more touchpoints within an organization not to speak of more core information sharing.<br /><br />

                    Thanks,<br />
                    Vandana</p>,
                Name: "Vandana bhardawaj",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Its very good experience till date to be in association with Wise Finserv. Keeps updating me with the market and other factor. Hope to have a healthy relationship in future as well.</p>,
                Name: "Sanjeev Kumar",
                Designation: "Student, Student"
            }, {
                imgUrl: VinayMohanJoshi,
                content: <p>Thanks<br /><br />

                    so far, i am satisfied with services offered Wise Finserv.<br /><br />

                    Regards,<br />
                    vinay mohan joshi</p>,
                Name: "Vinay Mohan Joshi",
                Designation: "HR manager, Qualtech Consultancy Pvt. Ltd."
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Hi Wise Finserv,<br /><br />

                    I have availed 3 sips on consultation
                    Initially i was unaware and reluctant to invest money in mutual fund, sip etc. Primarily due to lack of in-depth knowledge.
                    My advisor explained me functioning and pros-cons of several plans in a very simple language and convinced me to make investments.<br />
                    Looking forward to collaborate further.<br />
                    Thanks<br /><br />

                    Brijesh Singh<br />
                    Engineer Technical<br />
                    Energy Efficiency Services Ltd.</p>,
                Name: "Brijesh Singh",
                Designation: "Engineer, Energy efficiency services limited"
            },
        ],
        [
            {
                imgUrl: UmeshTaneja,
                content: <p>Your session regarding finance planning was an eye opener for me as it gives me food for thought how i need to plan for my financial goals. You people suggested some good plans also & i hope that will help me to attain my financial goals on future.<br /><br />

                    Best part working with you guys is prompt reply of queries & your honesty & dedication towards work. Keep it up.<br /><br />

                    Regards,<br />
                    umesh taneja</p>,
                Name: "Umesh Taneja",
                Designation: ""
            }, {
                imgUrl: women,
                content: <p>Dear team,<br /><br />

                    it was a wonderful experience of getting associated with Wise Finserv. The team, is very energetic, and prompt in giving right advice to their clients. They are just like doctor who do right diagnosis and then treat a patient. They are playing a vital role in managing in an appropriate way and hence growing it. Hence i am really delighted with their advisory and would definitely recommended their services to others.<br /><br />

                    Regards,<br />
                    anjali malhotra</p>,
                Name: "Anjali Malhotra",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hi,<br /><br />

                    It has been more than 6 months with Wise Finserv and i have got overwhelming services from your side. Your financial advises have helped me a lot to gain financial benefits which i have been always looking for. You are been always there to help me out with almost everything i required regarding my financial management. He is so diligent that even i get motivated from him. He has awesome knowledge regarding financial plannings. I love to discuss my financial aspects with him.<br />
                    I used to get daily updates from his side which keeps me updated.<br /><br />

                    Thanks for his diligence and profound knowledge.<br /><br />

                    I wish all the future growth to Wise Finserv<br /><br />

                    Thanks and best regards<br />
                    Rakesh Kumar</p>,
                Name: "Rakesh Kumar",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>I highly recommend to choose services from wisefinserv, as they follow a methodical approach in calculating your financial risk factors, they will create a plan as per your future financial essentials. They always make you feel free to get your doubts clear and never hesitate to visit, to give you the best picture of the financial products. Abhishek Singh and Vipin has helped me a lot to craft a strong financial planning, that I never had, with my current funds in hand, as well as with my in hand income. Looking forward to a long and productive relationship.
                    -Deb Chakraverty</p>,
                Name: "Client name",
                Designation: "Software Engineer, SDG Corporation"
            }, {
                imgUrl: men,
                content: <p>I highly recommend to choose services from Wise Finserv, as they follow a methodical approach in calculating your financial risk factors, they will create a plan as per your future financial essentials. They always make you feel free to get your doubts clear and never hesitate to visit, to give you the best picture of the financial products they helped me a lot to craft a strong financial planning, that i never had, with my current funds in hand, as well as with my in hand income. Looking forward to a long and productive relationship.<br /><br />

                    -Deb chakraverty</p>,
                Name: "Deb chakraverty",
                Designation: "Software engineer, Sdg corporation"
            },
        ],
        [
            {
                imgUrl: ShrutiChaubey,
                content: <p style={{ fontSize: "0.8vw" }}>Hello,

                    Thanks for the mail as a reminder of not forgetting those who are working for us and our future.<br />

                    Well i wanted to invest in the sip since long, but was not able to find a reliable medium.<br />

                    Doing it on own involved a lot of research and a bit of professional knowledge at least, which i believe is a tricky issue so i mostly ended up dropping the idea of investment. After many failed attempts i got contact of financial advisor and had conversation with them about sip.
                    He asked for certain details (which were really easy to provide) and in very short period of time got back to me with a plan.<br />

                    As i told him to suggest me a plan in which i can easily invest and which doesnt involve huge amount, he briefed me about a plan of birla sunlife which i found perfectly suited to my need.<br />

                    The next barrier for me was the documentations, but to my surprise it took me just 10mins to complete all of them because of my advisor’s guidance and finally i was just one step away from my 1st investment.<br />

                    Within few days the documents got submitted and i received a confirmation of my sip from the company which was exciting and relaxing at the same time.<br /><br />

                    I would really like to thank the Wise Finserv team for humbly tackling my queries and understanding my needs to suggest me the best possible option.<br /><br />

                    I congratulate and wish the WFS team a lot of success and many more coming years of great achievements and hope you guys keep supporting people in making their investment dreams true with your amazing service.<br /><br />

                    Thanks & regards<br />
                    Shruti Chaubey</p>,
                Name: "Shruti Chaubey",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: LeenaLaloo,
                content: <p>The services are satisfactory. Thanks</p>,
                Name: "Kashif Sayeed",
                Designation: ""
            }, {
                imgUrl: "https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705496882/Wise%20FinServ%20Private%20Wealth/Testimonials/leena_laloo_ueqdvr.webp",
                content: <p>I am glad that i was introduced to your company. I wish i was introduced earlier at the start of my career so i could have planned my investments better. Wish you all the best!<br /><br />

                    Thanks and regards,<br />
                    Dr. Leena laloo</p>,
                Name: "Leena Laloo",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p style={{ fontSize: "0.8vw" }}>Dear sir/ma'am,<br /><br />

                    I had never consulted any financial advisor for my financial plannings till 2016, but after my few meetings with Wise Finserv team i realised that how important financial planning is for every individual especially for working professionals. I am glad to receive your services, she has always given me all information regarding sip investment. Whenever i have any doubt she used to resolve all my query.i have full trust and faith in Wise Finserv consultancy because of her empathetic approach. All my investments on her advised have yielded good returns till now. Her response to my querries is like one discussing within a family. I have even given reference of urs to my near and dear one also.<br /><br />

                    Hope to get even more better service from you as well as your team in near future.<br /><br />

                    Regards,<br />
                    manash dey<br />
                    assistant professor,<br />
                    mechanical engineering department,<br />
                    northern india engineering college<br />
                    new delhi - 110053</p>,
                Name: "Manash Dey",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Hi Wise Finserv,<br /><br />

                    i am really glad to share my views as Wise Finserv has given me a very good platform to keep track of my investments and has given me ease of operation. Really happy with the service of Wise Finserv till date. Looking forward to long term association with Wise Finserv and team !</p>,
                Name: "Rohit Sharma",
                Designation: "Student, Student"
            }, {
                imgUrl: SanjeevSolanki,
                content: <p>“you are serving a customer, not a life sentence. Learn how to enjoy your work”<br /><br />

                    with best regards<br />
                    sanjeev solanki</p>,
                Name: "Sanjeev Solanki",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hi there,<br /><br />

                    I would like to thank Wise Finserv for such a brilliant guidance & help to understand the mutual fund term & its benefit.<br /><br />

                    They also suggested & helped me to find the best companies/mutual funds to invest, & also showing their dedication towards customer relationship by giving me the updates related to my investment.<br /><br />

                    Appreciate his work.<br /><br />

                    Thanks<br />
                    Rajesh</p>,
                Name: "Rajesh",
                Designation: "Manager, Navy Site India Pvt. Ltd."
            },
        ],
        [
            {
                imgUrl: AnkurSrivastava,
                content: <p>Dear WFS team,<br />
                    Though it's not a long duration since we are into connection but in the mean time i am having a valuable and good experience. I have been with some other advisors in the past but i didn't get a single platform where i could get all my investments records and this much of dedication towards clients portfolio. This portal facility is really helpful. Market related news and updates makes me day by day more aware, more keen and i appreciate your valuable efforts and times which you provide to your clients.</p>,
                Name: "Ankur Srivastava",
                Designation: "Probationary Officer, SBI"
            }, {
                imgUrl: AyusPratapSingh,
                content: <p>Hello team WFS,<br /><br />

                    As per your e-mail, i would like to share my experience with you all, as i am new to mf ,one of your financial advisors my financial advisor really helped me with understanding the market, also he helped me in forecasting my future expenses. My initial experience with WFS is really great. Hope to see this kind of support in future as well.<br /><br />

                    Looking forward for more investment through WFS.</p>,
                Name: "Ayush Pratap Singh",
                Designation: "Underwriter, ICICI Lombard General Insurance Company"
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>Dear sir/mam,<br /><br />

                    I am very pleased that i have found a right way (Wise Finserv) to increase my savings in the future. I have want to increase my savings in future. But i was scared that don't sink my savings in somewhere.<br /><br />

                    So once my friend suggest me your organisation and to meet me Wise Finserv financial adviser both are member provide me best investment plans as per my savings. Firstly i would thank to Wise Finserv financial services she is best financial adviser for me and my savings. I need to such financial adviser who suggest me make more money in future with legal and healthy way. Further i decided i would make more investment for long term with Wise Finserv<br /><br />

                    Thank you..</p>,
                Name: "Kamal Maurya",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: VivekKumarSingh,
                content: <p>Its very good experience till date to be in association with Wise Finserv. They keeps updating me with the market and other factor.hope to have a healthy relationship in future as well.</p>,
                Name: "Vivek Kumar Singh",
                Designation: ""
            }, {
                imgUrl: women,
                content: <p>Dear team,<br />
                    for handing my port polio and i will give her rating 5 out of 5.</p>,
                Name: "Meena Singh",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I have known WFS for a couple of months now and during this time i have been very impressed with their competence not only with their wise and skillful placement of our money but also their very professional organization. They regularly get in touch with us and also visit us several times in a year to review our situation and to make suggestions on any improvements they think they can make. They have a proactive approach to their work and, after each visit, they later follow up and forward a very detailed report explaining the current situation. I have been more than pleased with Wise Finserv services and would highly recommend them.<br /><br />

                    Regards<br />
                    digendra singh samant</p>,
                Name: "Digendra Singh Samant",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: men,
                content: <p>It’s my pleasure to be associated with Wise Finserv, please find my feedbacks below.<br /><br />

                    “I am happy and highly satisfied with the advisory services provided by Wise Finserv. Appreciate their efforts for best research done while preparing portfolio and recommendations on the investments according to requirement.
                    Team Wise Finserv owns decidedly skills and has comprehensive observation over market which assure excellent returns for their clients. Best tools, services and guidance make them best financial advisory company.
                    Team Wise Finserv wish you the best for all your endeavors.”<br /><br />

                    Regards, siddharth ashtankar</p>,
                Name: "Siddharth Ashtankar",
                Designation: ""
            }, {
                imgUrl: women,
                content: <p>Dear mam,<br /><br />

                    Its good to be associated with such a good company. Which takes cares of the portfolio and is genuine in its services. I will surely recommend WFS to others.</p>,
                Name: "Sushma Kaushik",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: RajeshSingh,
                content: <p>Thank you for your mail and it is a good initiative to take the customer feedback and opinion from time to time.
                    I am quite satisfied with the services provided by Wise Finserv so far.
                    Helps us to keep updated about the new developments of the market and always there to resolve our query and we really appreciate her approach and sincerity towards her work.<br /><br />

                    Thanks & with regards,<br />
                    rajesh rawat</p>,
                Name: "Rajesh Singh",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: DhaneshKaushik,
                content: <p>Dear ma'am,<br /><br />

                    Wise Finserv has given good services. I am quite satisfied with the services provided by my financial advisor</p>,
                Name: "Dhanesh Kaushik",
                Designation: ""
            }, {
                imgUrl: men,
                content: <p>Hi,<br /><br />

                    great associating with WFS. Complete guidance for portfolio management.<br /><br />

                    Prompt addressal of queries.<br /><br />

                    Thanks</p>,
                Name: "Rohit Kumar",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hey,<br /><br />

                    my association with Wise Finserv has not only opened prospects of financial investments but also increased my knowledge base regarding investment options. She is great in her job and represents your firm at utmost professionalism.<br /><br />

                    I would like to thank my portfolio manager and wise fiserv for updating me on daily changes in the market and make the interface more user-friendly. Looking forward towards future endeavours together.<br /><br />

                    Regards<br />
                    mridalini rai</p>,
                Name: "Mridalini Rai",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hi concern,<br /><br />

                    I am really happy to share my opinion. Wise Finserv has given me a very good platform to keep track of my investments. Really happy with the services. As they is very prompt and helpful. Really very happy with the services of my advisor<br /><br />

                    Regards<br />
                    Narender Singh</p>,
                Name: "Narender Singh",
                Designation: ""
            }, {
                imgUrl: men,
                content: <p>I am writing this to extend my heartiest appreciation towards priyanka for her work which she does very sincerely. My association with Wise Finserv is great as it has benefited me and have always worked towards the progress of my goal. I truly appreciate the professionalism by which she handles my portfolio and guide me to take prompt action. With this attitude of her , i have started developing the trust in your organization and also recommend others to join with you.<br />

                    Thanks ..!!</p>,
                Name: "Mrinal Abhinav",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Wise Finserv team come for presentation in our office. From so much time i think about to contact some finance advisor, but confuse where to go. So i think to contact Wise Finserv<br /><br />

                    we have around two hour conversation on first day. They understood my requirement with full interest and analysis them and provide best suggestions.<br /><br />

                    Ekta give her 100% effort to to understand the requirements and continue in touch even after the office hours.<br /><br />

                    Vipin always give satisfactory answer to all the queries.<br /><br />

                    Hope that this collaboration will go for long time.<br /><br />

                    All the best to both of you for the future.<br /><br />

                    Thanks<br />
                    darshan</p>,
                Name: "Darshan",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: women,
                content: <p>Hi<br />

                    my experience has been great throughout, i have been associated with them for long now, they are always there for you, service is excellent, providing regular updates through mails and calls, have complete trust and always providing valuable advise. Undoubtedly i would strongly recommend them for any financial services needs.<br /><br />

                    Thanks<br /><br />

                    regards<br />
                    Rakhi gandhi</p>,
                Name: "Rakhi Gandhi",
                Designation: ""
            }, {
                imgUrl: men,
                content: <p>Good in most of aspects.<br />
                    keep it up.<br />
                    Regards<br />
                    Rr</p>,
                Name: "Rajesh Rohal",
                Designation: ""
            },
        ],
        [

            {
                imgUrl: RaghvendraSingh,
                content: <p>This is my first ever investment and my experience is really good. Investment related advisory services given by Wise Finserv is really impressive and valuable. He is really good at communicating information on time. He is handling my investment nicely, as i am also seeing that my investment is continuously growing day by day.<br /><br />

                    I really happy with services rendered by Wise Finserv.<br /><br />

                    Thank you<br />
                    raghvendra singh</p>,
                Name: "Raghvendra Singh",
                Designation: "Operation Executive, Optima Insurance Brokers Pvt. Ltd."
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>In my life, financial planning was my long awaited dream which could not be fulfilled by doing myself and could not be successful also. When i could get an opportunity to interact with Wise Finserv, i have full satisfaction with them and the way i was planning, i could get support from them to drive in right way.</p>,
                Name: "Jayan Nambissan",
                Designation: ""
            }, {
                imgUrl: UmeshKumarSingh,
                content: <p>Thanks for continue support and guide.<br />
                    Very good experience with you and will be continue to take your advice</p>,
                Name: "Umesh Kumar Singh",
                Designation: "Team leader, TI Infotech pvt. ltd"
            },
        ],
        [

            {
                imgUrl: PiyushSingh,
                content: <p>Dear sir/ma'am,<br /><br />

                    i would like to take this opportunity to say a very big thank you to Wise Finserv for the wonderful help given to me over the past for months that has really made a difference to my budget and to get advice on that. I’m now using my money in a more organised way.
                    You helped me so much that i am now confidant of the need, my need to exercise better and more sound financial budgeting decisions.<br />
                    Thanks for your all time support..<br /><br />

                    Regards<br />
                    piyush singh</p>,
                Name: "Piyush Singh",
                Designation: "Executive, KSB Pumps Pvt. Ltd."
            },
        ],
        [
            {
                imgUrl: LalitKhajuria,
                content: <p>Hi<br />
                    i am associated with WFS from approx one year. I am completely satisfied with the response/services by WFS team(vipin & vinshu) who are interacting with me.<br /><br />

                    Regards,<br />
                    lalit khajuria.</p>,
                Name: "Lalit Khajuria",
                Designation: "Project Manager, Ingersollrand Climate solution Pvt. Ltd."
            }, {
                imgUrl: men,
                content: <p>Hi<br />

                    "They comes up as a sincere financial advisor with deep knowledge of various financial products and good customer service "<br /><br />

                    regards<br />
                    Nitin Maheshwari</p>,
                Name: "Nitin Maheshwari",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Dear team WFS,<br />

                    Thank you for your invite to formally capture my feedback for services provided by your team of professionals.<br /><br />

                    What i get first thing in my mind is rigorous though very considerate follow up. And i really appreciate that the follow ups are always something for the benefit of mine. I always get a prompt response for any of my requests or for any major market updates. WFS has always been very particular about keeping me informed with all the possible updates or requested solutions to my financial apprehensions.<br /><br />

                    To summarize, i would like to appreciate the spirit with which services are provided by WFS. I feel lot satisfied the way i have been guided to manage my finances wisely. Your customer relationship skills are indeed commendable. Wish you many happy customers like me in the years to come !<br /><br />

                    Thanks & regards,<br />
                    Tanuj saxena

                </p>,
                Name: "Tanuj Saxena",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hi Team,<br />

                    I just want to say thanks for everything . He helped me to guide on the investment strategies ,purchasing sips, one time investment and other saving which helped me to get good returns along with save tax.<br /><br />

                    He also guided me about Wise Finserv portal to purchase new sip's , get live reports etc,<br /><br />

                    Thanks for your support and guidance.<br /><br />

                    Regards<br />
                    Nikhil Gupta</p>,
                Name: "Nikhil Gupta",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p style={{ fontSize: "0.8vw" }}>Sirs,<br /><br />

                    it might be difficult task for me to provide a testimonial about the organization, the Wise Finserv. Yet a limited feed back could be possible based on my mediocre interactions; commencing with ms vandana first, followed by ms kajal in last couple of years and now, comprehensive discussions with ms priyanka. With my pleasant experience with them i have no hesitation to say "the culture of the company speaks by itself".<br /><br />

                    I had been having very fruitful interactions with you & absolutely clear in concepts, excellent delivery, and above all authoritatively convincing power. She is simply superb with pleasing personality. She has been the catalyst to change my mind set of investments from 'fixed deposits' to 'mutual funds'. Didn’t find her sparing any effort in her professional approach in bringing out new or on-going products. She successfully consolidated my portfolios.<br /><br />

                    Through her i seek your company's assistance in reviving my equity holdings which are hitherto lying dormant for last 25-30 years, although she readily offered her services to sort out the hold ups.<br /><br />

                    Wising her a great future.<br /><br />

                    In the end i propose that your company may introduce a information system for the clients about some new products/schemes through personal mails. This may base on clients' age, requirement, capacity to invest etc.<br /><br />

                    Sincerely,<br />
                    N. K. Bhatia</p>,
                Name: "N.K. Bhatia",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>Hi,<br /><br />

                    I would like to take this opportunity to say a very big thank you to Wise Finserv team for this wonderful experience given to me over the past one year. The Wise Finserv online portal is really very amazing & mobile app is also very awesome.<br /><br />

                    I would specially like to thank ms. Swati malik for managing my portfolio. She is very organized, focussed, disciplined & very sincere for her work. She is an excellent communicator also.<br /><br />

                    Keep up the good work.<br /><br />

                    Regards,<br />
                    Mudit bhan | employee relations<br />
                    Escorts agri machinery | component plant</p>,
                Name: "Mudit Bhan",
                Designation: ""
            },
        ],
        [
            {
                imgUrl: men,
                content: <p>I can only say it has been pleasure to be associated with WFS for last 2 yrs or so. The company which is headed by dynamic and professional people like ajay and charu, has a very special place in my thoughts.<br /><br />

                    I am very small investor but i have been serviced beyond my expectations. She is evergreen and ready to assist u at any time. Her approach and convincing power needs special mention. One thing which i being defence oldy like is punctuality and frankness, which she has in abundance.<br /><br />

                    I know, u must be having great role in moulding her under guidance of ajay and charu. Pl be free for any clarifications.<br /><br />

                    Wish WFS a great fy 2017-18. God bless all those who make WFS family.pl do read thought for this week given below<br /><br />

                    CDR Narinder Mahajan<br />
                    Director, shaheed smarak sanstha, noida<br />
                    An efficient organisation</p>,
                Name: "Narinder Mahajan",
                Designation: ""
            }
        ]
    ]


    return (
        <div className="home-testimonial">
            <div className="page-title">
                <h4>Testimonials</h4>
            </div>
            <div className="carousel">
                <div class="carousel-wrapper">
                    <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} interval={4000} swipeable={false}>
                        {data.map((item) => (
                            <div className="testimonial-body">
                                {item.map((datas) => (
                                    <div class="testimonial">
                                        <span class="open quote">“</span>
                                        <div class="image">
                                            <div class="clip"></div>
                                            <img src={datas.imgUrl} />
                                        </div>
                                        <div class="source">{datas.content}</div>
                                        <div className="source-text">
                                            <span>{datas.Name}</span>
                                            <p>{datas.Designation}</p>
                                        </div>
                                        <span class="close quote">”</span>
                                    </div>
                                ))}
                            </div>
                        ))}

                    </Carousel>
                </div>
            </div>


        </div>
    )
}

export default Testimonial
