import React, { useState, useEffect } from 'react'
import fb from "../../AdminPanel/Blog/firebase";
import banner from '../../Images/Career/banner.webp'
import Footer from '../../Utilities/Footer';
import Header from '../../Utilities/Header';
import JobFormForStudents from './JobFormForStudents';
const DB = fb.firestore()
const OFSList = DB.collection('Opportunities For Students');

const CareerForStudents = () => {

    const [careerofs, SetCareerofs] = useState([]);
    const elementsArray = [];

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // fetching data from database for career for students
        const OFS = OFSList.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetCareerofs(data);
        });

        // Detach listener
        return OFS;
        // fetching data from database for career ends
    }, []);

    // opportunities for Students
    const [StudentsShownFullTime, setStudentsShownFullTime] = useState(false);
    const [StudentsShownInternship, setStudentsShownInternship] = useState(false);
    const [StudentsShownAll, setStudentsShownAll] = useState(true);

    const Sfulltime = () => {
        setStudentsShownFullTime(true);
        setStudentsShownInternship(false);
        setStudentsShownAll(false);

        document.getElementById('s-full-time').style.borderBottom = "3px solid #b76c41";
        document.getElementById('s-all-programs').style.borderBottom = "3px solid #fff";
        document.getElementById('s-internships').style.borderBottom = "3px solid #fff";
    }

    const Sinternship = () => {
        setStudentsShownInternship(true);
        setStudentsShownFullTime(false);
        setStudentsShownAll(false);
        document.getElementById('s-full-time').style.borderBottom = "3px solid #fff";
        document.getElementById('s-all-programs').style.borderBottom = "3px solid #fff";
        document.getElementById('s-internships').style.borderBottom = "3px solid #b76c41";
    }

    const Sall = () => {
        setStudentsShownAll(true);
        setStudentsShownInternship(false);
        setStudentsShownFullTime(false);
        document.getElementById('s-full-time').style.borderBottom = "3px solid #fff";
        document.getElementById('s-all-programs').style.borderBottom = "3px solid #b76c41";
        document.getElementById('s-internships').style.borderBottom = "3px solid #fff";
    }
    const [loc, setLoc] = useState("");

    const filtered = careerofs.filter(careers => {
        return careers.Location.map(data => data.label === loc)
    });


    return (
        <div >
            <Header />
            <JobFormForStudents />

            {/* { 
                careerofs.Location.forEach(cart => 
                    (<FlatList
                        data={this.props.products.filter(product => product.id === cart.id)}
                        key={cart.id}/>)
                );
            } */}

            <div className="career-programs" id='opportunities-for-students'>
                <div className="c-p-banner">
                    <img src={banner} alt="" />
                    <h5>Opportunities for students</h5>
                </div>
                <div className="c-p-categories">
                    <button id='s-all-programs' onClick={Sall}>all programs</button>
                    <button id='s-internships' onClick={Sinternship}>internships</button>
                    <button id='s-full-time' onClick={Sfulltime}>full-time</button>
                </div>
               
                <div className='c-p-categories-body'>
                    {StudentsShownFullTime && (
                        <div className="c-p-categories-content">
                            {careerofs.filter(careers => careers.Job_Type === 'Full-Time').map(careers => (
                                <div className="c-p-c-c-card" key={careers.id}>
                                    <h4>{careers.Job_Type}</h4>
                                    <h5>{careers.Job_Title}</h5>
                                    <ul>
                                        <li>Locations:</li>
                                        {careers.Location.map((datas) => (
                                            <li key={datas}>{datas.label},</li>
                                        ))}
                                    </ul>
                                    <div className="c-p-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#JobFormForStudents">Apply Now</button>
                                        <a target='_blank' href={careers.JD} >Download JD</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {StudentsShownInternship && (
                        <div className="c-p-categories-content">
                            {careerofs.filter(careers => careers.Job_Type === 'Internship' && careers.Location.filter(datas => datas.label === loc)).map(careers => (
                                <div className="c-p-c-c-card" key={careers.id}>
                                    <h4>{careers.Job_Type}</h4>
                                    <h5>{careers.Job_Title}</h5>
                                    <ul>
                                        <li>Locations:</li>
                                        {careers.Location.map((datas) => (
                                            <li key={datas}>{datas.label},</li>
                                        ))}
                                    </ul>
                                    <div className="c-p-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#JobFormForStudents">Apply Now</button>
                                        <a target='_blank' href={careers.JD} >Download JD</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}

                    {StudentsShownAll && (
                        <div className="c-p-categories-content">
                            {filtered.map(careers => (
                                <div className="c-p-c-c-card" key={careers}>
                                    <h4>{careers.Job_Type}</h4>
                                    <h5>{careers.Job_Title}</h5>
                                    <ul>
                                        <li>Locations:</li>
                                        {careers.Location.map((datas) => (
                                            <li key={datas}>{datas.label},</li>
                                        ))}
                                    </ul>
                                    <div className="c-p-btn">
                                        <button data-bs-toggle="modal" data-bs-target="#JobFormForStudents">Apply Now</button>

                                        <a target='_blank' href={careers.JD} >Download JD</a>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default CareerForStudents
