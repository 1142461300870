import React, { useState, useEffect } from "react";
import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const BlogsCategories = DB.collection('blog-categories');

const BCategory = () => {
    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        const unsubscribe = BlogsCategories.onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    return (
        <div>
            {blogs.map(blog => (
                <div className="bcat">
                    <p>{blog.Category}</p>
                </div>
            ))}
        </div>
    )
}

export default BCategory
