import React, { useEffect, useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import fb from "../AdminPanel/Blog/firebase";
import BCategory from '../Utilities/BCategory';
import LatestPosts from '../Utilities/LatestPosts';
import { Helmet } from 'react-helmet'

const DB = fb.firestore()
const Blogslist = DB.collection('blogs');

const Blog = () => {

  const [blogs, Setblogs] = useState([]);

  useEffect(() => {
    // 👇️ scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      // Update state
      Setblogs(data);
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <div>
      <Header />
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="https://www.wisefinserv.com/blog" />
      </Helmet>
      <div className="blog">
        <div class="page-title">
          <h4>Blogs</h4>
        </div>
        <div className="blog-body">
          <div className="blog-body-content">
            {blogs.map(blog => (
              <div className="blog-card">
                <img src={blog.Image} alt="" />
                <div className="blog-card-text">
                  <span>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" /></svg> */}
                    {blog.Date}
                  </span>

                  <Link to={"/show/" + blog.id}>{blog.Title}</Link>
                  <div className="category">
                    <h6>Category:&nbsp;</h6>
                    {blog.Categories.map((data) => (
                      <p key={data.id}>{data}</p>
                    ))}
                  </div>
                  <div className='body' dangerouslySetInnerHTML={{ __html: blog.Body }}></div>
                  <Link className='Read' to={"/show/" + blog.id}>Read More</Link>
                </div>
              </div>
            ))}
            <hr />
          </div>

          <div className="blog-body-text">
            <div className="blog-body-categories">
              <h4>Categories</h4>
              <BCategory />
            </div>
            <div className="blog-body-categories">
              <h4>Latest Posts</h4>
              <LatestPosts />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Blog
