import React from 'react'
import Footer from '../Utilities/Footer'
import Header from '../Utilities/Header'

const InvestorGrievance = () => {
    return (
        <>
            <Header />
            <div className="privacy">

                <div className="page-title">
                    <h4>Wise Finserv- Investor Grievance</h4>
                </div>
                <div className="privacy-body">
                    <h5>To register a formal complaint with Wise Finserv Pvt. Ltd., please write to &nbsp;
                    <a href="mailto: compliance@wisefinserv.com">compliance@wisefinserv.com</a>&nbsp;with your:</h5>
                    <ol>
                        <li>Full Name</li>
                        <li>Registered Mobile Number</li>
                        <li>Pan Card</li>
                        <li>Nature of Complaint</li>
                    </ol>
                    <h5>Investors, who are not satisfied with the resolution provided by client service team, may escalate
                        the matter to following officials as per the below escalation matrix:</h5>
                    <p>Level 1 – contact Ms Shalvi Chawla , Head- Operations &amp; Customer Service @
                        shalvichawla@wisefinserv.com or call her at 0120-4114832</p>
                    <p>Level 2 – contact Ms Charu Pahuja, Director &amp; COO @ charupahuja@wisefinserv.com</p>
                    <p>Level 3 – contact Mr. Ajay Kumar Yadav, CEO @ ajay.yadav@wisefinserv.com</p>
                </div>


            </div>
            <Footer />
        </>
    )
}

export default InvestorGrievance