import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Helmet } from 'react-helmet'


// Images =========================================

import eae from "../Images/Why Choose Us/Expertise_and_Experience_rv2axk.webp"
import tfs from "../Images/Why Choose Us/Tailored_Financial_Solutions_nioy8a.webp"
import cs from "../Images/Why Choose Us/Comprehensive_Services_dptint.webp"
import cca from "../Images/Why Choose Us/Client-Centric_Approach_rukjnt.webp"
import at from "../Images/Why Choose Us/Advanced_Technology_zwjxop.webp"
import rm from "../Images/Why Choose Us/Risk_Management_p61iqj.webp"
import gr from "../Images/Why Choose Us/Global_Reach_twvgnv.webp"
import cte from "../Images/Why Choose Us/Commitment_to_Education_assixs.webp"
import eari from "../Images/Why Choose Us/Ethical_and_Responsible_Investing_maqvmd.webp"
import ptr from "../Images/Why Choose Us/proven_track_record_gqy8si.webp"

// Images Ends ====================================

const WhyUs = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const whyChooseUs = [
        {
            icon: eae,
            title: "Expertise and Experience",
            description: "Wise Finserv brings a wealth of experience to the table. Our team comprises seasoned professionals with extensive knowledge of financial markets, investment strategies, tax planning, and estate management. With a track record of delivering results, we have the expertise to navigate complex financial landscapes."
        },

        {
            icon: tfs,
            title: "Tailored Financial Solutions",
            description: "We understand that every client has unique financial goals, risk tolerance, and circumstances. At Wise Finserv, we don't offer one-size-fits-all solutions. Instead, we take the time to understand your individual needs and design custom-tailored financial plans and investment portfolios to help you achieve your specific objectives."
        },
        {
            icon: cs,
            title: "Comprehensive Services",
            description: "Wise Finserv offers a full spectrum of financial services. Whether you're looking for investment portfolio management, retirement planning, estate planning, tax optimization, or any other financial need, our team has the expertise to provide holistic solutions that align with your financial goals."
        },

        {
            icon: cca,
            title: "Client-Centric Approach",
            description: "We prioritize our clients' interests above all else. Our client-centric approach means that your financial well-being and peace of mind are at the forefront of every decision we make. We are committed to transparency, open communication, and building long-lasting relationships based on trust."
        },
        {
            icon: at,
            title: "Advanced Technology",
            description: <p>We leverage cutting-edge technology to provide you with real-time access to your financial information and performance metrics. Our secure online portals and digital tools ensure that you have complete visibility and control over your wealth. <i style={{ color: '#b76c41' }}>Our website is 256 bit encrypted & secured.</i></p>
        },
        {
            icon: rm,
            title: "Risk Management",
            description: "Wise Finserv is dedicated to managing risk effectively. We conduct thorough risk assessments and develop investment strategies that align with your risk tolerance, ensuring a balanced and suitable approach to wealth accumulation."
        },
        {
            icon: gr,
            title: "Global Reach",
            description: "In today's interconnected world, global diversification is essential for wealth preservation and growth. Wise Finserv offers expertise in international markets, helping you diversify your investments to capitalize on opportunities worldwide."
        },
        {
            icon: cte,
            title: "Commitment to Education",
            description: "We believe in empowering our clients with knowledge. Wise Finserv provides regular updates on financial markets, economic trends, and investment strategies, ensuring that you are well-informed and can make confident financial decisions."
        },
        {
            icon: eari,
            title: "Ethical and Responsible Investing",
            description: "We take environmental, social, and governance (ESG) factors into account when constructing investment portfolios. If you have a preference for responsible and sustainable investing, Wise Finserv can align your investments with your values."
        },
        {
            icon: ptr,
            title: "Proven Track Record",
            description: "Our history of success and satisfied clients speaks to the quality of our services. We have consistently delivered strong returns while managing risk prudently, providing our clients with financial security and peace of mind."
        }
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Why Choose Wise FinServ | Unlocking Your Wealth Potential</title>
                <link rel="canonical" href="https://www.wisefinserv.com/why-us" />
                <meta name="description" content="Choosing Wise FinServ Private Wealth means entrusting your financial future to a team of dedicated professionals. With expertise, tailored financial solutions, comprehensive services, a client-centric approach, advanced technology, and an unwavering commitment, we help you achieve your financial goals. Explore our proven track record and robust service support structure." />
                <meta name="keywords" content="Why Choose Wise FinServ, expertise, tailored financial solutions, comprehensive services, client-centric approach, advanced technology, risk management, global reach, commitment to education, ethical investing, proven track record, service support" />
            </Helmet>
            <Header />
            <div className="why-us-page">

                <div className="page-title">
                    <h4>Why Choose Us</h4>
                </div>

                <div className="why-us-page-body">

                    <div className="why-us-page-first">
                        <p>Choosing the right wealth manager is a critical decision that can profoundly impact your financial future. Wise Finserv Private Wealth stands out as a premier choice for individuals seeking expert guidance and personalized financial solutions. Here are compelling reasons to consider Wise Finserv as your trusted wealth manager</p>
                    </div>

                    <div className="why-us-page-content">
                        {whyChooseUs.map((item) => (
                            <div className="why-us-page-card">
                                <div className="why-us-page-card-img">
                                    <img src={item.icon} alt={item.title} />
                                </div>
                                <div className="why-us-page-card-caption">
                                    <h4>{item.title}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}

                        <div className="why-us-page-card">
                            <div className="why-us-page-card-img">
                                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1705580163/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/robust_service_support_structure_h6wzsy.png" alt="Our Robust Service Support Structure" />
                            </div>
                            <div className="why-us-page-card-caption">
                                <h4>Our Robust Service Support Structure</h4>
                                <p>Our history of success and satisfied clients speaks to the quality of our services. We have consistently delivered strong returns while managing risk prudently, providing our clients with financial security and peace of mind.</p>

                                <ul>
                                    <li>Dedicated Service Manager</li>
                                    <li>Regular Interaction with the product team on portfolio performance and Mandatory Periodic Reviews to track the trajectory of the portfolio</li>
                                    <li>Unit Head Regularly Interact with clients along with Wealth Manager & the product team to foster a long-term relationship</li>
                                    <li>The Director & CEO proactively Engage wiuth client and add insights to the portfolio To deliver Higher Alpha</li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="why-us-page-first">
                        <p>Choosing Wise Finserv Private Wealth as your wealth manager means entrusting your financial future to a team of dedicated professionals who are committed to your success. With our personalized approach, comprehensive services, and unwavering commitment to your financial well-being, we are confident in our ability to help you achieve your financial goals and aspirations. Your journey to financial prosperity begins with Wise Finserv.</p>
                    </div>


                </div>

            </div>
            <Footer />
        </div>
    )
}

export default WhyUs
