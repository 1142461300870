import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import WhatWeOfferHome from '../Utilities/WhatWeOfferHome'
import { Helmet } from 'react-helmet'

// Images ========================================
import MedicalProfessionals from "../Images/What we offer/for medical professionals.webp"
import Entrepreneur from "../Images/What we offer/For Entrepreneurs.webp"
import CorporateProfessionals from "../Images/What we offer/For Corporate Professionals.webp"
import SelfEmployedIndividuals from "../Images/What we offer/For Self-Employed Individuals.webp"
import NRI from "../Images/What we offer/For NRIs (Non-Resident Indians).webp"
import Retirees from "../Images/What we offer/For Retirees.webp"

// Images ends ===================================

const WhatWeOffer = () => {
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const whatLast = [
        {
            img: MedicalProfessionals,
            title: "For Medical Professionals",
            description: "Your dedication to the well-being of others deserves equal commitment to your financial well-being. Our specialized offerings for medical professionals encompass a holistic approach to wealth management. We recognize the challenges and opportunities unique to your profession, from managing student debt to planning for retirement. Our financial experts work closely with you to optimize your financial health, allowing you to focus on what you do best—caring for your patients."
        },
        {
            img: Entrepreneur,
            title: "For Entrepreneurs",
            description: "As an entrepreneur, your financial journey is intertwined with the success of your business. Our wealth solutions for entrepreneurs are designed to align your personal and business financial goals seamlessly. We provide guidance on capital allocation, risk management, and strategies to help you grow your wealth while safeguarding your business's future."
        },
        {
            img: CorporateProfessionals,
            title: "For Corporate Professionals",
            description: "Climbing the corporate ladder often comes with complex financial decisions. Our specialized services for corporate professionals address executive compensation, stock options, and retirement planning. We aim to maximize your financial potential while ensuring you have a clear roadmap to reach your post-career goals."
        },
        {
            img: SelfEmployedIndividuals,
            title: "For Self-Employed Individuals",
            description: "Self-employment offers freedom and challenges in equal measure. We assist self-employed individuals in optimizing their financial strategies, from tax-efficient planning to retirement savings. Our solutions are customized to your entrepreneurial spirit and financial objectives."
        },
        {
            img: NRI,
            title: "For NRIs (Non-Resident Indians)",
            description: "Living abroad shouldn't keep you from managing your finances effectively in your home country. Our NRI-focused offerings bridge the geographical gap. We provide comprehensive advice on investment, taxation, and asset protection, ensuring your financial interests remain secure, no matter where you reside."
        },
        {
            img: Retirees,
            title: "For Retirees",
            description: "Retirement marks the culmination of a lifetime of hard work and planning. Our retirement-focused solutions cater to retirees, addressing income planning, estate management, and legacy planning. We help you enjoy a comfortable and worry-free retirement while securing your family's financial future."
        },
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Our Services | Wise FinServ Private Wealth | Unlocking Your Wealth Potential</title>
                <link rel="canonical" href="https://www.wisefinserv.com/what-we-offer" />
                <meta name="description" content="Explore Wise FinServ's comprehensive private wealth management services. We provide Exclusive Wealth Strategy, Tax-Efficient Investing, Global Investment Diversification, Legacy Planning, Financing Solutions, and Niche Portfolio Solutions. Tailored services for Medical Professionals, NRIs, Entrepreneurs, Retirees, and Corporate Professionals." />
                <meta name="keywords" content="Wise FinServ, private wealth management, wealth strategy, tax-efficient investing, global investment diversification, legacy planning, financing solutions, niche portfolio solutions, medical professionals, NRIs, entrepreneurs, retirees, corporate professionals" />
            </Helmet>
            <Header />
            <div className="what-we-offer-page">

                <div className="page-title">
                    <h4>What we offer</h4>
                </div>

                <WhatWeOfferHome />
                <div className="what-last">
                    {whatLast.map((item) => (
                        <div className="what-last-card">
                            <img src={item.img} alt={item.title} />
                            <div className="what-last-caption">
                                <h4>{item.title}</h4>
                                <p>{item.description}</p>
                            </div>
                        </div>
                    ))}

                    <div className="what-last-last">
                        <p>At Wise Finserv , our team of seasoned professionals possesses deep expertise in each of these specialized areas. We take the time to understand your unique circumstances, objectives, and aspirations. Then, we craft personalized wealth solutions that align with your vision for the future.</p>
                        <p>Our commitment to your financial well-being goes beyond conventional wealth management. We strive to empower you to make informed decisions, navigate life's financial complexities, and ultimately achieve the financial freedom you deserve.</p>
                        <p>These services collectively aim to optimize wealth accumulation, protection, and distribution while providing clients with peace of mind and financial security.</p>
                    </div>

                </div>
            </div>

            <Footer />
        </div>
    )
}

export default WhatWeOffer
