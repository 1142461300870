import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { useParams, Link } from 'react-router-dom';
import fb from "../AdminPanel/Blog/firebase";
import BCategory from '../Utilities/BCategory';
import LatestPosts from '../Utilities/LatestPosts';
import { Helmet } from 'react-helmet'
const DB = fb.firestore()
const Blogslist = DB.collection('blogs');

const BlogDetails = () => {

    const { id } = useParams();
    const [blogs, Setblogs] = useState([]);
    Blogslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setblogs(data);
    });


    return (
        <div>
            <Header />
            <Helmet>
                <meta charSet="utf-8" />
                <link rel="canonical" href={"https://www.wisefinserv.com/show/" + id} />
            </Helmet>

            <div className="blog">
                <div className="blog-body">
                    <div className="blog-body-content">
                        <div className="blog-card blog-details">
                            <img src={blogs.Image} alt={blogs.Title} />
                            <div className="blog-card-text">
                                <span className='date'>{blogs.Date}</span>

                                <h1>{blogs.Title}</h1>
                                <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                            </div>
                        </div>
                    </div>

                    <div className="blog-body-text">
                        <div className="blog-body-categories">
                            <h4>Categories</h4>
                            <BCategory />
                        </div>
                        <div className="blog-body-categories">
                            <h4>Latest Posts</h4>
                            <LatestPosts />
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="blog-body">
                <div className="blog-body-content">
                    <div className="blog-details-img">
                        <img src={blogs.Image} alt="" />
                        <span></span>
                    </div>
                    <div className="blog-details-text">
                        <h1>{blogs.Title}</h1>
                        <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                    </div>
                </div>

                <div className="blog-body-text">
                    <div className="blog-body-categories">
                        <h4>Categories</h4>
                        <BCategory />
                    </div>
                    <div className="blog-body-categories">
                        <h4>Latest Posts</h4>
                        <LatestPosts />
                    </div>
                </div>
            </div> */}
            {/* <div className="blog-details">
                <div className="blog-details-img">
                    <img src={blogs.Image} alt="" />
                    <span></span>
                </div>
                <div className="blog-details-text">
                    <h1>{blogs.Title}</h1>
                    <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                </div>
            </div > */}
            <Footer />
        </div >
    )
}

export default BlogDetails
