import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth"
// import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyC_Q-EIjiVTLLIesBJ8-tewXof0JOjQr6w",
    authDomain: "wise-finserv-cf8b2.firebaseapp.com",
    projectId: "wise-finserv-cf8b2",
    storageBucket: "wise-finserv-cf8b2.appspot.com",
    messagingSenderId: "270934284454",
    appId: "1:270934284454:web:386b6c1f842794f04d3c67",
    measurementId: "G-LVVEPK34DP"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
