import React from 'react'
import { Link } from 'react-router-dom'
import homeAbout from "../Images/About us/home-about.webp"
import ews from "../Images/What we offer/Exclusive_Wealth_Strategy.webp"
import tei from "../Images/What we offer/Tax_Efficient_Investing.webp"
import gid from "../Images/What we offer/Global_Investment_Diversification.webp"
import lp from "../Images/What we offer/Legacy_Planning.webp"
import fs from "../Images/What we offer/Financing_Solutions.webp"
import nps from "../Images/What we offer/Niche_Portfolio_solutions.webp"

const WhatWeOfferHome = () => {

    const whatWeOffer = [
        {
            imgurl: ews,
            title: "Exclusive Wealth Strategy",
            description: "Crafting customized strategies to grow and preserve your wealth, tailored to your individual financial objectives and aspirations."
        },

        {
            imgurl: tei,
            title: "Tax Efficient Investing",
            description: "Focus on tax-efficient investing by offering tax-advantaged products and strategies that help clients optimize their after-tax returns."
        },

        {
            imgurl: gid,
            title: "Global Investment Diversification",
            description: "Unlock global opportunities with our International Investment Solutions, tailored to help you diversify and grow your wealth across borders."
        },

        {
            imgurl: lp,
            title: "Legacy Planning",
            description: "Cultivate your legacy with our Estate Planning expertise. We guide you in safeguarding your wealth, ensuring a seamless transfer to future generations. "
        },

        {
            imgurl: fs,
            title: "Financing Solutions",
            description: "Unlock the potential of your dreams with our tailored Financing Solutions. From loans to strategic funding, we pave the way for your financial success."
        },

        {
            imgurl: nps,
            title: "Niche Portfolio solutions",
            description: "At Wise Finserv , we understand that financial aspirations vary, and there's no one-size-fits-all solution to wealth management. That's why we take pride in offering Specialized Niche Offerings tailored to the unique needs of specific professional segments. Whether you're a medical professional, entrepreneur, corporate executive, self-employed individual, NRI (Non-Resident Indian), or retiree, our goal is to empower you to achieve your financial dreams through bespoke financial solutions. "
        },
    ]
    return (
        <div>
            <div class="what-we-offer">
                <div className="title">
                    <h4>What we offer</h4>
                </div>
                <div className="what-we-offer-body">
                    {whatWeOffer.map((item) => (
                        <div className="what-card">
                            <img src={item.imgurl} alt={item.title} />
                            <div className="what-card-text">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <Link to={"/what-we-offer"}>Read More</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default WhatWeOfferHome
