import React, { useState, useEffect } from 'react'
import fb from "../../AdminPanel/Blog/firebase";
import banner from '../../Images/Career/Untitled design.webp'
import Footer from '../../Utilities/Footer';
import Header from '../../Utilities/Header';
import JobFormForProfessionals from './JobFormForProfessionals';
const DB = fb.firestore()
const OFPList = DB.collection('Opportunities For Professionals');

const CareerForProfessionals = () => {

    const [careerofp, SetCareerofp] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        // fetching data from database for career for students
        const OFP = OFPList.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetCareerofp(data);
        });

        // Detach listener
        return OFP;
        // fetching data from database for career ends

    }, []);
    const [loc, setLoc] = useState("");


    return (
        <div>
            <Header />
            <JobFormForProfessionals />

            <div className="career-programs" id='opportunities-for-professionals'>
                <div className="c-p-banner">
                    <img src={banner} alt="" />
                    <h5>opportunities for professionals</h5>
                </div>

                <div className='c-p-categories-body'>

                    <div className="c-p-categories-content">

                        {careerofp.map(careers => (
                            <div className="c-p-c-c-card">
                                <h4>Full-Time</h4>
                                <h5>{careers.Job_Title}</h5>
                                <ul>
                                    <li>Locations:</li>
                                    {careers.Location.map((datas) => (
                                        <li key={datas}>{datas.label},</li>
                                    ))}
                                </ul>
                                <div className="c-p-btn">
                                    <button data-bs-toggle="modal" data-bs-target="#JobFormForProfessionals">Apply Now</button>

                                    <a target='_blank' href={careers.JD} >Download JD</a>
                                </div>
                            </div>
                        ))}
                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default CareerForProfessionals
