import React, { useEffect, useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'
import "animate.css/animate.min.css";
import InvestmentPhilosophy from '../Utilities/InvestmentPhilosophy'
import Testimonial from '../Utilities/Testimonial'
import { Link } from 'react-router-dom'
import NumberTicker from '../Utilities/NumberTicker'
import WhyChooseUs from '../Utilities/WhyChooseUs';
import { Helmet } from 'react-helmet'

// images =============================
import homeAbout from "../Images/About us/home-about.webp"
import ews from "../Images/What we offer/Exclusive_Wealth_Strategy.webp"
import tei from "../Images/What we offer/Tax_Efficient_Investing.webp"
import gid from "../Images/What we offer/Global_Investment_Diversification.webp"
import lp from "../Images/What we offer/Legacy_Planning.webp"
import fs from "../Images/What we offer/Financing_Solutions.webp"
import nps from "../Images/What we offer/Niche_Portfolio_solutions.webp"
import robust from "../Images/Superior Tech Platform/robust.webp"
import degree from "../Images/Superior Tech Platform/degree.webp"
import onboarding from "../Images/Superior Tech Platform/onboarding.webp"
import extensive from "../Images/Superior Tech Platform/extensive.webp"
import wiseBonds from "../Images/Superior Tech Platform/wise bonds.webp"
import wiseFinserv from "../Images/Superior Tech Platform/wise finserv.webp"

// images ends========================

import fb from "../AdminPanel/Blog/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('blogs');

const Home = () => {
    const [blogs, Setblogs] = useState([]);

    useEffect(() => {
        // 👇️ scroll to top on page load

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const unsubscribe = Blogslist.limit(3).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            Setblogs(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    const whatWeOffer = [
        {
            imgurl: ews,
            title: "Exclusive Wealth Strategy",
            description: "Crafting customized strategies to grow and preserve your wealth, tailored to your individual financial objectives and aspirations."
        },

        {
            imgurl: tei,
            title: "Tax Efficient Investing",
            description: "Focus on tax-efficient investing by offering tax-advantaged products and strategies that help clients optimize their after-tax returns."
        },

        {
            imgurl: gid,
            title: "Global Investment Diversification",
            description: "Unlock global opportunities with our International Investment Solutions, tailored to help you diversify and grow your wealth across borders."
        },

        {
            imgurl: lp,
            title: "Legacy Planning",
            description: "Cultivate your legacy with our Estate Planning expertise. We guide you in safeguarding your wealth, ensuring a seamless transfer to future generations. "
        },

        {
            imgurl: fs,
            title: "Financing Solutions",
            description: "Unlock the potential of your dreams with our tailored Financing Solutions. From loans to strategic funding, we pave the way for your financial success."
        },

        {
            imgurl: nps,
            title: "Niche Portfolio solutions",
            description: "At Wise Finserv , we understand that financial aspirations vary, and there's no one-size-fits-all solution to wealth management."
        },
    ]

    const superiorTechPlatfrom = [
        {
            img: robust,
            text: "Robust Technology Platforms to help you track your portfolio 24X7"
        },
        {
            img: degree,
            text: "360 degree Portfolio Management : Track and Manage across multiple asset classes"
        },
        {
            img: onboarding,
            text: "2-Minute Onboarding on Wise Funds App : A Digital Initiative of Wise FinServ"
        },
        {
            img: extensive,
            text: "Extensive P&L Reports to give you better clarity on your gains"
        },
        {
            img: wiseBonds,
            text: "Wise Bonds : A robust platform to track and purchase"
        },
        {
            img: wiseFinserv,
            text: "Wise FinServ : A secure platform with OTP and PIN Authentication to protect your privacy"
        },
    ]

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Wise FinServ Private Wealth | Unlocking Your Wealth Potential</title>
                <link rel="canonical" href="https://www.wisefinserv.com/" />
                <meta name="description" content="Unlock your wealth potential with Wise FinServ. Expert financial solutions, personalized wealth management, and strategic investments. Choose us with confidence for Mutual Funds, Fixed Incomes, Tax-Efficient Investing, and Legacy Planning. Tailored services for Medical Professionals and NRIs." />
                <meta name="keywords" content="Wise FinServ, wealth potential, financial solutions, wealth management, strategic investments, Mutual Funds, Fixed Incomes, Tax-Efficient Investing, Legacy Planning, Medical Professionals, Insurance , life health , LIC " />
            </Helmet>
            <Header />
            <Banner />

            {/* About */}
            <div className="about">
                <div className="about-img">
                    <img src={homeAbout} alt="about" />
                    <div className="image-overlay"></div>
                </div>
                <div className="about-caption">
                    <h3>About wise finServ private wealth</h3>
                    <p>
                        We offer simple investment options tailored to clients' needs, serving HNI and UHNI. Market-leading qualifications assure clients that their future is in capable hands. Our comprehensive services cover personal finance globally. Our innovative approach and strategic partnerships ensure exceptional client experiences. Services include investment advisory, portfolio management, tax planning, insurance, and retirement planning.
                    </p>
                    <p>
                        At Wise Finserv, we prioritize technology and innovation, utilizing digital platforms for efficiency and real-time market insights. We measure success by our clients' achievements and financial security. Upholding integrity and transparency, we empower clients to make informed decisions. With a client-centric focus, experienced professionals, and cutting-edge tools, we guide clients toward a secure future.

                    </p>
                </div>
            </div>
            {/* About ends */}

            {/* why us */}
            {/* <div className="why-us">
                <div className="title">
                    <h4>Why Choose Us</h4>
                </div>

                <div className="why-us-body">
                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674241/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/5_hvrzp6_kgprgl.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674225/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Expertise_and_Experience_rv2axk.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Expertise and Experience</h4>
                            <p>Wise Finserv brings a wealth of experience to the table. Our team comprises seasoned professionals with extensive knowledge of financial markets, </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>

                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028544/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/Tailored_Financial_Solutions_rtxlw7.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028450/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Tailored_Financial_Solutions_nioy8a.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Tailored Financial Solutions</h4>
                            <p>We understand that every client has unique financial goals, risk tolerance, and circumstances. At Wise Finserv, we don't offer one-size-fits-all solutions. Instead</p>
                            <a href=''>Read More &nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>

                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028545/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/Comprehensive_Services_nxhh3d.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1697028450/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Comprehensive_Services_dptint.png" alt="" />                        </div>
                        <div className="why-us-card-caption">
                            <h4>Comprehensive Services</h4>
                            <p>Wise Finserv offers a full spectrum of financial services. Whether you're looking for investment portfolio management, retirement planning, estate planning, </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>

                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674229/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/11_bd6rvb_vdnn4j.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674221/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Client-Centric_Approach_rukjnt.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Client-Centric Approach</h4>
                            <p>We prioritize our clients' interests above all else. Our client-centric approach means that your financial well-being and peace of mind are at the forefront of </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>


                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674230/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/12_wus7tz_r9yqgz.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674220/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Advanced_Technology_zwjxop.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Advanced Technology</h4>
                            <p>We leverage cutting-edge technology to provide you with real-time access to your financial information and performance metrics. Our secure online</p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>


                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674245/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/Risk_Management_safxvm.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674227/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Risk_Management_p61iqj.png" alt="" />
                            <div className="why-us-card-caption">
                                <h4>Risk Management</h4>
                                <p>Wise Finserv is dedicated to managing risk effectively. We conduct thorough risk assessments and develop investment strategies that align with your</p>
                                <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                            </div>
                        </div>
                    </div>
                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674243/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/Global_Reach_t1vvx4.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674226/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Global_Reach_twvgnv.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Global Reach</h4>
                            <p>In today's interconnected world, global diversification is essential for wealth preservation and growth. Wise Finserv offers expertise in international markets, helping </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>


                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674231/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/13_bvnvgz_mz1cae.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674222/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Commitment_to_Education_assixs.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Commitment to Education</h4>
                            <p>We believe in empowering our clients with knowledge. Wise Finserv provides regular updates on financial markets, economic trends, and investment </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>

                    </div>

                    <div className="why-us-card">
                        <div className="why-us-card-img">
                            <img className='why-us-card-hidden' src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674232/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/white/18_erzjed_xiorvk.png" alt="" />
                            <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1696674224/Wise%20FinServ%20Private%20Wealth/Why%20Choose%20Us/copper/Ethical_and_Responsible_Investing_maqvmd.png" alt="" />
                        </div>
                        <div className="why-us-card-caption">
                            <h4>Ethical and Responsible Investing</h4>
                            <p>We take environmental, social, and governance (ESG) factors into account when constructing investment portfolios. If you have a preference </p>
                            <a href=''>Read More&nbsp; <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg></a>
                        </div>

                    </div>

                </div>
            </div> */}
            <WhyChooseUs />
            {/* why us ends */}

            {/* what we offer */}
            <div class="what-we-offer">
                <div className="title">
                    <h4>What we offer</h4>
                </div>
                <div className="what-we-offer-body">
                    {whatWeOffer.map((item) => (
                        <div className="what-card">
                            <div className="what-card-img">
                                <img src={item.imgurl} alt={item.title} />
                            </div>
                            <div className="what-card-text">
                                <h3>{item.title}</h3>
                                <p>{item.description}</p>
                                <Link to={"/what-we-offer"}>Read More</Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            {/* what we offer ends */}


            {/* investment philosophy */}
            <InvestmentPhilosophy />
            {/* investment philosophy ends */}


            {/* superior-tech-platform */}
            <div className="superior-tech-platform">
                <div className="title">
                    <h4>Superior Tech Platform</h4>
                </div>
                <div className="superior-tech-platform-body">
                    {superiorTechPlatfrom.map((item) => (
                        <div className="s-t-p-card">
                            <img src={item.img} alt={item.text} />
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* superior-tech-platform ends */}

            {/* number-ticker */}
            <NumberTicker />
            {/* number-ticker ends */}

            {/* testimonails */}
            <Testimonial />
            {/* testimonials ends */}

            {/* Blog */}
            <div className="home-blog">
                <div className="title">
                    <h4>Blogs</h4>
                </div>
                <div className="home-blog-body">
                    {blogs.map(blog => (
                        <div class="home-blog-card">
                            <div class="card-image">
                                <img src={blog.Image} alt="blog Image" />
                            </div>
                            <div class="card-text">

                                <Link to={"/show/" + blog.id}>{blog.Title}</Link>

                                <div className='body' dangerouslySetInnerHTML={{ __html: blog.Body }}></div>
                            </div>
                            <p class="post-timestamp">{blog.Date}</p>
                        </div>
                    ))}
                </div>
            </div>
            {/* Blog ends */}

            <Footer />
        </div >
    )
}

export default Home
